img.float-images-default {
  object-fit: contain;
  position: absolute;
  z-index: -1;

  &.float-images-home-1 {
    top: 85px;
    left: -297px;
    width: 518px;
    height: 478px;
    @media (max-width: 1200px) {
      top: 380px;
      left: -110px;
      width: 331px;
      height: 306px;
    }
  }
  &.float-images-home-2 {
    top: 281px;
    left: 1035px;
    width: 461px;
    height: 622px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &.float-images-home-3 {
    top: -522px;
    left: -350px;
    width: 554px;
    height: 652px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  &.float-images-home-4 {
    width: 588px;
    height: 611px;
    //top: 3266px;
    //left: 880px;
    //top: 3216px;
    top: 3176px;
    left: 875px;
    @media (max-width: 1200px) {
      top: -46px;
      left: 187px;
      width: 294px;
      height: 306px;
    }
  }
  &.float-images-home-5 {
    //top: 3992px;
    left: -238px;
    width: 492px;
    height: 579px;
    //top: 3939px;
    top: 3894px;

    @media (max-width: 1200px) {
      top: -12px;
      left: -71px;
      width: 246px;
      height: 266px;
    }
  }
  &.float-images-home-6 {
    width: 533px;
    height: 664px;
    top: -561px;
    //left: 699px;
    left: 850px;

    @media (max-width: 1200px) {
      width: 267px;
      height: 332px;
      top: -564px;
      left: 220px;
    }
  }

  &.float-images-home-7 {
    z-index: -1;
    transform: rotate(100deg);
    left: -430px;
    top: 2739px;
    width: 654px;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &.float-images-footer-6 {
    width: 603px;
    height: 557px;
    top: -579px;
    left: 1000px;
    @media (max-width: 1200px) {
      width: 362px;
      height: 334px;
      top: 515px;
      left: 120px;
    }
  }

  &.float-images-product-7 {
    top: -80px;
    left: -120px;
    width: 638px;
    height: 459px;
    @media (max-width: 1200px) {
      top: -40px;
      left: -57px;
      width: 319px;
      height: 230px;
    }
  }

  &.float-images-product-8 {
    //top: -111px;
    top: -80px;
    left: -266px;
    width: 605px;
    height: 509px;
    z-index: -1;
    transform: rotateY(180deg);
    @media (max-width: 1200px) {
      z-index: -1;
      //top: -55px;
      //left: 38px;
      //left: 5px;
      top: 10px;
      left: -86px;
      width: 303px;
      height: 255px;
    }
  }
  &.float-images-product-9 {
    top: -89px;
    left: -185px;
    width: 635px;
    height: 510px;
    @media (max-width: 1200px) {
      top: -43px;
      //left: -90px;
      left: -61px;
      width: 318px;
      height: 255px;
    }
  }
  &.float-images-product-11 {
    transform: rotateY(180deg);
    //left: -398px;
    //top: -48px;
    //height: 473px;
    //width: 643px;
    left: -344px;
    top: 53px;
    height: 430px;
    width: 590px;
    @media (max-width: 1200px) {
      left: 1200px;
    }
  }
  &.float-images-product-14 {
    position: absolute;
    width: 439px;
    height: 501px;
    left: 275px;
    top: -95px;
    @media (max-width: 1200px) {
      //width: 202px;
      //height: 233px;
      //left: 149px;
      //top: -50px;
      //top: -32px;
      width: 220px;
      height: 233px;
      left: 158px;
      top: -54px;
    }
  }
  &.float-images-resources-7 {
    position: absolute;
    top: -74px;
    left: -177px;
    @media (max-width: 1200px) {
      width: 320px;
      height: 230px;
      top: -44px;
      left: -58px;
    }
  }
  &.float-images-resources-14 {
    position: absolute;
    top: 97px;
    right: -182px;
    @media (max-width: 1200px) {
      width: 300px;
      height: 290px;
      top: 36px;
      right: -94px;
    }
  }
  &.float-images-resources-3 {
    position: absolute;
    transform: rotate(66deg) scaleX(-1);
    //top: -188px;
    top: -166px;
    left: -196px;

    @media (max-width: 1200px) {
      width: 251px;
      height: 323px;
      left: -57px;
      //top: -96px;
      top: -82px;
    }
  }
  &.float-images-resources-12 {
    position: absolute;
    top: 32px;
    right: -239px;

    @media (max-width: 1200px) {
      width: 369px;
      height: 251px;
      //top: -51px;
      top: -32px;
      right: -60px;
    }
  }
  &.float-images-plans-10 {
    top: -264px;
    left: 915px;
    width: 655px;
    height: 495px;
    @media (max-width: 1200px) {
      top: -84px;
      left: 170px;
      width: 364px;
      height: 275px;
    }
  }
  &.float-images-plans-11 {
    top: 467px;
    left: -288px;
    width: 713px;
    height: 525px;
    @media (max-width: 1200px) {
      top: 1710px;
      left: -200px;
      width: 396px;
      height: 292px;
    }
  }
  &.float-images-plans-12 {
    bottom: 104px;
    left: 654px;
    width: 693px;
    height: 470px;
    @media (max-width: 1200px) {
      bottom: 80px;
      left: 75px;
      width: 369px;
      height: 250px;
      transform: rotateY(180deg);
    }
  }
  &.float-images-demo-13 {
    top: -151px;
    left: -425px;
    width: 620px;
    height: 488px;
    @media (max-width: 1200px) {
      top: -84px;
      left: -305px;
      width: 372px;
      height: 293px;
    }
  }
  &.float-images-demo-14 {
    top: 359px;
    left: 884px;
    width: 532px;
    height: 514px;
    @media (max-width: 1200px) {
      top: 690px;
      left: 230px;
      width: 299px;
      height: 289px;
    }
  }

  &.float-images-error404-16 {
    top: 481px;
    left: -158px;
    width: 727px;
    height: 576px;
    @media (max-width: 1200px) {
      top: 420px;
      left: -25px;
      width: 289px;
      height: 229px;
    }
  }
  &.float-images-security-11 {
    top: -20px;
    left: 230px;
    transform: rotateY(180deg);

    &.float-images-security-11-us {
      top: -64px;
    }
  }
  &.float-images-security-17 {
    top: 264px;
    left: -190px;
    width: 527px;
    height: 495px;
    @media (max-width: 1200px) {
      left: -90px;
      width: 211px;
      height: 197px;
      top: 421px;
      &.brFloat {
        //top: 361px;
        //top: 444px;
        top: 568px;
      }
    }
  }
  &.float-images-security-18 {
    top: 489px;
    left: 802px;
    width: 694px;
    height: 496px;
    @media (max-width: 1200px) {
      top: 505px;
      left: 255px;
      width: 250px;
      height: 178px;
    }
  }

  &.float-images-legal-19 {
    top: 99px;
    left: 822px;
    width: 606px;
    height: 436px;
    transform: rotate(-10deg);
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &.float-images-customers-20 {
    width: 464px;
    height: 433px;
    top: -16px;
    left: 914px;
    transform: rotateY(180deg) rotateZ(68deg);
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &.float-images-customers-21 {
    width: 605px;
    height: 509px;
    top: 385px;
    left: -293px;
    transform: rotateZ(15deg);
    @media (max-width: 1200px) {
      width: 273px;
      height: 230px;
      top: -241px;
      left: -163px;
    }
  }

  &.float-images-customers-22 {
    top: 634px;
    left: 919px;
    width: 506px;
    height: 468px;
    @media (max-width: 1200px) {
      width: 302px;
      height: 279px;
      top: 2150px;
      left: 144px;
    }
  }

  &.float-images-customers-23 {
    top: -32px;
    left: -225px;
    width: 714px;
    height: 494px;
    @media (max-width: 1200px) {
      width: 390px;
      height: 270px;
      top: -60px;
      left: -146px;
    }
  }

  &.float-images-customers-24 {
    width: 394px;
    height: 410px;
    top: 42px;
    left: 1032px;
    transform: rotateZ(-30deg);
    @media (max-width: 1200px) {
      display: none;
    }
  }

  &.float-images-customers-25 {
    width: 620px;
    height: 420px;
    top: 280px;
    left: -238px;
    transform: rotateZ(180deg);
    @media (max-width: 1200px) {
      display: none;
    }
  }
}
