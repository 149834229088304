section.email {
div {
background-color: #ffffff; 
max-width: 920px;
min-width: 920px;
min-height: 666px;
margin: 0 auto;
margin-bottom: 457px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 13px;
box-shadow: 0 2px 8px 0 $shadowColor;

@media(max-width:1200px){
  min-width: 312px;
  height: 810px;
  padding: 0 16px;
  margin-bottom: 180px;
}

h2 {
    font-size: 21px;
    font-weight: bold;
    margin: 0;
}
img {
    margin-top: 32px;
}
p {
    color: #8d99aa;
    text-align: center;
    max-width: 600px;
    margin: 16px 0 56px 0;
}
form.pick-hour-container {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 1200px) {
    margin: 44px 0 56px 0;
    }
    input.pick-hour-item {
        width: 420px;
        height: 66px;
        font-size: 16px;
        margin-bottom: 24px;
        color: black;
        border: solid 1px $darkFont;
        border-radius: calc(420px / 2);
        padding: 0 17px 0 24px ;
        background-repeat: no-repeat;
        background-color: #ffffff;
        background-size: 18px;
        background-position: right 20px bottom 22px;
        -webkit-appearance: none;
        -moz-appearance: none;
        &:focus {
          outline: none;
        }
        &:required:invalid {
          color: $grayFont;
        }
        option {
          color: $darkFont;
          background-color: #ffffff;
        }
        @media (max-width: 1200px) {
          width: 280px;
          height: 44px;
          background-position: right 20px bottom 10px;
        }
      }
      
}
button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    
}
}
}

// MODAL CONFIG

// MODAL BUTTON
  
input.accordionButton{
  display: none;
}

div.div-modal{
  display: none;
}

// MODAL 
section.modal-accordion-menu{

  .accordionButton:checked + .modal-accordion-menu-container{
    div.div-modal{
      background-color: #ffffff; 
      width: 944px;
      z-index: 9999;
      max-height: 100%;
      margin: 0 auto;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 13px;
      box-shadow: 0 2px 8px 0 $shadowColor;

      //MEDIA DO MODALZINHO
      @media(max-width:1200px){
        max-width: 312px;
        height: 651px;
        margin: 0 auto;        
      }
      
      h2 {
          font-family: "Fira Sans", sans-serif;
          font-size: 42px;
          font-weight: 600;     
          line-height: 46px;   
          margin: 38px 0 24px 0;  
          
          @media(max-width: 1200){
            font-size: 32px;
            line-height: 36px;
            margin-top: 40px;
          }
      }
      p {
        font-family:'Open Sans', sans-serif;
        max-width: 600px;
        text-align: center;
        margin-bottom: 28px;
        color: #5c6473;
        font-size: 16px;

        @media(max-width: 1200){
          width: 239px;
        }
      }
      button.button-modal{
        margin-bottom: 64px;
      }
      img.modal-image{
        width: 505px;
        margin-bottom: 86px;

        @media(max-width: 1200px){
          width: 285px;
        }
        
      }
      }
    }

    .accordionButton:not(:checked) + .modal-accordion-menu-container {
      section.modal-accordion-menu{
        display: none;
      }
    }


  }



