section.plans-boxes {
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 1200px) {
    flex-direction: column;
    margin: 0;
    margin-bottom: 164px;
  }
  article.plans-boxes-item {
    &:nth-child(3) {
      //margin-right: 56px;
      margin-right: 100px;
      @media (max-width: 1200px) {
        margin: 0;
      }
    }
    h2 {
      font-size: 34px;
      text-align: left;
      font-weight: 800;
      margin-bottom: 32px;
      @media (max-width: 1200px) {
        align-self: flex-start;
        font-size: 32px;
      }
    }
    div {
      padding: 48px 30px 64px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 470px;
      max-height: 765px;
      border-radius: 13px;
      box-shadow: 0 2px 8px 0 $shadowColor;
      background-color: #ffffff;
      @media (max-width: 1200px) {
        width: 313px;
        max-height: initial;
        padding: 48px 32px 64px 32px;
      }
      img.boxes-img {
        &-1 {
          width: 177px;
          height: 206px;
        }
        &-2 {
          width: 197px;
          height: 206px;
        }
      }
      h3 {
        margin: 24px 0;
        font-size: 24px;
        font-family: "Fira Sans", sans-serif;
        font-weight: 800;
        color: $boxesTitleColor;
        line-height: 1.42;
        letter-spacing: 0.55px;
        @media (max-width: 1200px) {
          text-align: center;
          margin-bottom: 16px;
        }
      }
      ul {
        width: 100%;
        padding-left: 42px;
        list-style: none;
        @media (max-width: 1200px) {
          padding-left: 0;
        }
        li {
          margin-bottom: 16px;
          color: $grayFont;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          letter-spacing: 0.36px;
          &:last-child {
            margin-bottom: 0px;
          }
          @media (max-width: 1200px) {
            margin-left: 24px;
            width: 226px;
          }
        }

        li.business-plan {
          opacity: 0.3;
          text-decoration: line-through;
        }
      }

      a {
        margin-top: 48px;
        button {
          display: flex;
          justify-content: space-around;
          width: 226px;
          align-items: center;
          img {
            margin-left: 8px;
            width: 26px;
            height: 23px;
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    min-width: 100%;
    max-width: 313px;

    article.plans-boxes-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      &:nth-child(3) {
        margin-bottom: 96px;
      }
      h2 {
        max-width: 100%;
        margin-bottom: 24px;
      }
    }
  }
}

section.plans-accordion-menu {
  //margin-bottom: 192px;
  margin-bottom: 162px;
  padding: 0 80px;
  @media (max-width: 1200px) {
    padding: 0;
    //margin-bottom: 128px;
    margin-bottom: 64px;
    max-width: 313px;
  }
  article {
    h2 {
      margin-bottom: 32px;
      border-bottom: 1px solid #5393fe;
      padding-bottom: 56px;
      font-size: 34px;
      @media (max-width: 1200px) {
        font-size: 32px;
        //padding-bottom: 40px;
      }
    }

    .accordionButton:checked + .plans-accordion-menu-container {
      p {
        opacity: 1;
        max-height: 480px;
        margin-bottom: 32px;
        margin-top: 24px;
        padding: 0 78px 0 40px;
        letter-spacing: 0.36px;
        @media (max-width: 1200px) {
          width: 272px;
          padding: 0;
          margin-top: 16px;
        }
      }

      img.accordion-menu-expand-arrow {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
        filter: saturate(100%) brightness(0) invert(46%) sepia(91%)
          saturate(1614%) hue-rotate(200deg) brightness(105%) contrast(99%);
      }
      h3 {
        color: #5393fe;
      }
    }
    .accordionButton:not(:checked) + .plans-accordion-menu-container {
      img.accordion-menu-expand-arrow {
        transform: rotate(0deg);
        transition: transform 0.3s ease;
        filter: brightness(0) saturate(100%) invert(14%) sepia(10%)
          saturate(1121%) hue-rotate(180deg) brightness(96%) contrast(92%);
        @media (hover: hover) {
          &:hover {
            filter: saturate(100%) brightness(0) invert(46%) sepia(91%)
              saturate(1614%) hue-rotate(200deg) brightness(105%) contrast(99%);
          }
        }
      }
      //correção do texto aumentando de tamanho ao clicar para fechar
      p {
        @media (max-width: 1200px) {
          width: 272px;
          padding: 0;
        }
      }
      // fim
      h3 {
        color: $darkFont;
      }
    }
    input {
      display: none;
    }

    div.plans-accordion-menu-container {
      display: flex;
      border-bottom: solid 1px #5393fe;
      flex-direction: column;
      position: relative;
      background-color: #f9fafa;
      //margin-bottom: 40px;
      margin-bottom: 31px;

      div.plans-accordion-menu-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 19px;
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
          letter-spacing: 0.43px;
        }
        @media (hover: hover) {
          h3:hover {
            color: $hoverText;
          }
        }

        img {
          width: 22px;
          height: 14px;
          cursor: pointer;
        }
      }
      p {
        overflow: hidden;
        color: $grayFont;
        padding: 32px 78px 0 40px;
        max-height: 0px;
        transition: all 0.4s ease;
        em {
          font-weight: 700;
          font-style: initial;
        }
      }
    }
    @media (max-width: 1200px) {
      width: 100%;
      width: 100%;
      margin: 0px;
      padding: 0;
      div.plans-accordion-menu-container {
        padding: 0;
        margin-bottom: 32px;
        transition: max-height 0.3s ease-in-out;
        div.plans-accordion-menu-title {
          width: 100%;
          @media (max-width: 1200px) {
            margin-bottom: 0;
          }
          h3 {
            font-size: 19px;
            width: 272px;
          }
          img {
            height: 14px;
            object-fit: none;
          }
        }
        p {
          padding: 0 24px 0 0;
          margin-bottom: 32px;
          overflow: hidden;
          pointer-events: none;
          max-height: 0;
        }
      }
    }
  }
}

figure.plans-last-image-container {
  //margin-bottom: 104px;
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    //height: 163px;
    //margin-bottom: 96px;
    margin-bottom: 13px;
    width: 402px;
    height: 256px;
  }
  .plans-HYPE {
    width: 799px;
    height: 409px;
    @media (max-width: 1200px) {
      width: 402px;
      //height: 196px;
      //width: 482px;
      height: 256px;
      width: 402px;
      position: relative;
      left: -43px;
    }
  }
  img.plans-last-image-item {
    margin: 0 auto;
    align-items: center;
    width: 799px;
    height: 409px;
    transform: rotateY(180deg);
    @media (max-width: 1200px) {
      width: 322px;
      height: 166px;
      position: absolute;
    }
  }
}
