section.demo-main-title-container {
  overflow-x: hidden;
  text-align: center;
  max-width: 880px;
  margin: 88px 0 -26px 0; //calendly tem margin nativa inacessível
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    //max-width: 787px;
    max-width: 830px;
    letter-spacing: 0.48px;
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin: 72px 0 48px 0;
    h1 {
      font-size: 36px;
      width: 200px;
      margin-bottom: 16px;
    }
    p {
      max-width: 312px;
      letter-spacing: 0.36px;
    }
  }
}
