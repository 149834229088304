section.security-main-container {
  display: flex;
  margin-bottom: 105px;
  &.security-section-us {
    margin-bottom: 13px;
    @media (max-width: 1200px) {
      margin-bottom: 104px;
    }
  }
  @media (max-width: 1200px) {
    margin-bottom: 104px;
  }
  h2 {
    margin-bottom: 72px;
    font-family: "Fira Sans", sans-serif;
    font-weight: 800;
    font-size: 42px;
    @media (max-width: 1200px) {
      font-size: 34px;
    }
  }
  .security-grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    :last-child {
      grid-column: span 2;
      margin-bottom: 0px;
      position: relative;
      .security2-HYPE {
        width: 592px;
        height: 324px;
        top: 54px;
      }
      .lockerimg {
        width: 592px;
        height: 324px;
        top: 54px;

        &.locker-img-us {
          top: 5px;
        }
      }
      figure {
        position: relative;
        img {
          position: absolute;
          width: 685px;
          height: 505px;
        }
      }
    }
    :nth-child(8) {
      margin-bottom: 0px;
      margin-top: 6px;
    }

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      :nth-child(10) {
        display: none;
        grid-column: auto;
      }
      :last-child {
        grid-column: auto;
        margin-bottom: 0px;
      }
    }
    li {
      width: 345px;
      margin-right: 82px;
      //margin-bottom: 112px;
      margin-bottom: 98px;
      &.middle-row {
        margin-bottom: 92px;
      }

      &.li-us {
        margin-bottom: 92px;
        @media (max-width: 1200px) {
          margin-bottom: 112px;
        }
      }

      &:nth-child(2) > img {
        margin-bottom: 14px;
      }
      @media (max-width: 1200px) {
        width: 312px;
        margin: auto;
        display: flex;
        margin: auto;
        //margin-bottom: 112px;
        margin-bottom: 88px;
        flex-direction: column;
        &.middle-row {
          margin-bottom: 0px;
          @media (max-width: 1200px) {
            //margin-bottom: 112px;
            margin-bottom: 88px;
          }
        }
        &:nth-child(8) {
          //margin-bottom: 112px;
          margin-bottom: 56px;
          margin-top: 0px;
        }
        &:nth-child(3) {
          margin-bottom: 0px;
        }
        &:last-child {
          margin-bottom: 0px;
          p.father-gradient {
            position: relative;
            div.text-gradient {
              position: absolute;
            }
          }
        }
      }
      &.desktop-hide {
        display: none;

        @media (max-width: 1200px) {
          display: block;
          position: relative;
          div {
            width: 100%;
            height: 227px;
            background-image: linear-gradient(
              to bottom,
              rgba(249, 250, 250, 0) 10%,
              #f9fafa 77%
            );
            position: absolute;
            top: -227px;
          }
          &.less {
            display: none;
            margin-bottom: 0px;
          }
          &.more {
            margin-bottom: 0px;
          }
        }
      }
      button.mobile-button {
        display: none;
        @media (max-width: 1200px) {
          display: flex;
          width: 228px;
          align-items: center;
          justify-content: space-around;
          margin: 0 auto;
          background-color: transparent;
          color: #2979ff;
          border: 2px solid #2979ff;

          &.more {
            margin-top: 0px;
          }
          &:active {
            background-color: $hoverBlueButton;
          }
          img {
            margin: 0;
          }
        }
      }

      img {
        margin: auto;
        margin-bottom: 19px;
        @media (max-width: 1200px) {
          margin-left: 0px;
          margin-bottom: 24px;
        }
      }
      h3 {
        // margin-bottom: 24px;
        margin-bottom: 16px;
        font-size: 26px;
      }
      p {
        color: $grayFont;
        line-height: 1.5;
        letter-spacing: 0.36px;
        //height: 240px;
        @media (max-width: 1200px) {
          height: initial;
        }
        &.text-gradient {
          @media (max-width: 1200px) {
            max-height: 310px;
            position: relative;
            visibility: visible;
            bottom: 0;
            width: 100%;
            background-image: linear-gradient(
              to bottom,
              rgba(249, 250, 250, 0) 10%,
              #f9fafa 97%
            );
          }
        }
      }
    }
  }
}
// ACCORDION

.accordionButton:checked + .security-grid {
  li {
    &:nth-child(3) {
      margin-bottom: 112px;
      @media (max-width: 1200px) {
        margin-bottom: 88px;
      }
    }
    &.desktop-hide {
      &.less {
        display: none;
      }
      button.mobile-button {
        img.accordion-menu-expand-arrow {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }
      }
      button.less {
        visibility: visible;
      }
      @media (max-width: 1200px) {
        &.more {
          display: none;
        }
        &.less {
          display: block;
        }
      }
    }
  }
}

.accordionButton:not(:checked) + .security-grid {
  li.collapsible-content {
    @media (max-width: 1200px) {
      display: none;
    }
  }
  button.less {
    display: none;
  }
}

.forma-11 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
  top: -73px;
  left: 236px;
  width: 761px;
  height: 561px;
}

.security-link {
  text-decoration: none;
  text-decoration: underline;
  color: #2979ff;
}
