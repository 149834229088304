section.home-boxes {
  margin-bottom: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1200px) {
    margin-bottom: 168px;
  }

  &.snip-can-do {
    @media (max-width: 1200px) {
      //margin-bottom: 152px;
      margin-bottom: 136px;
    }
  }

  &.grid-box {
    margin-top: 0px;
    margin-bottom: 184px;
    @media (max-width: 1200px) {
      width: 312px;
      height: auto;
      margin-bottom: 82px;
    }
    div.grid-section {
      width: 1200px;
      margin: auto 120px;
      @media (max-width: 1200px) {
        width: 312px;
        height: auto;
      }
      ul.grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        object-fit: contain;
        @media (max-width: 1200px) {
          display: flex;
          flex-direction: column;
        }
        li.grid-cell {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 72px;
          margin-right: 104px;
          height: 194px;
          object-fit: contain;
          @media (max-width: 1200px) {
            display: flex;
            flex-direction: column;
            height: auto;
            margin: auto;
            margin-top: 64px;
            margin-bottom: 24px;
          }
          figure.panel-img {
            display: block;
            text-align: center;
            @media (max-width: 1200px) {
              margin-bottom: 19px;
            }
            img.cell-img {
              width: 179px;
              height: 194px;
              background-color: #74defe;
            }
          }
          div {
            h3.cell-title {
              width: 345px;
              height: 68px;
              margin-bottom: 16px;
              margin-left: 24px;
              font-family: "Fira Sans", sans-serif;
              font-size: 24px;
              font-weight: 800;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.55px;
              color: #5c6473;

              @media (max-width: 1200px) {
                margin-left: 0px;
                height: auto;
                width: auto;
              }
            }
            p.cell-text {
              margin-left: 24px;
              margin-top: 16px;
              height: 72px;
              @media (max-width: 1200px) {
                margin-left: 0px;
                height: auto;
                width: auto;
              }
            }
            a {
              &.cell-anchor {
                display: inline-flex;
                margin-left: 24px;
                margin-top: 16px;

                @media (max-width: 1200px) {
                  margin-left: 0px;
                }

                &.cell-anchor img {
                  margin-left: 24px;
                }
                &.cell-anchor p {
                  color: #3caf85;
                  text-decoration: underline;
                  font-family: "Open Sans", sans-serif;
                  font-size: 16px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0.36px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.carousel-box {
    //margin-bottom: 199px;
    margin-bottom: 70px;
    @media (max-width: 1200px) {
      margin-bottom: 0px;
    }
    h2.styledTitle {
      @media (max-width: 1200px) {
        //margin-bottom: 24px;
        margin-bottom: 16px;
        width: 312px;
        //height: 108px;
      }
    }
    p.styledSubtitle {
      @media (max-width: 1200px) {
        width: 312px;
        //height: 120px;
      }
    }
    article.carousel {
      width: 1076px;
      height: 749px;

      //margin-top: 78px;
      //margin-top: 28px;
      margin-top: 22px;
      justify-content: center;

      @media (max-width: 1200px) {
        //width: 360px;
        //height: auto;
        //height: 229px;
        //width: 502px;
        //height: 813px;
        //width: 360px;
        //height: 477px;
        width: 482px;
        height: 398px;
        margin-top: 0px;
        //margin-top: 78px;
        display: none;
      }
      div.single-item {
        img.carouselimg {
          @media (max-width: 1200px) {
            height: 223px;
            width: 313px;
          }
        }
        button.slick-arrow {
          @media (max-width: 1200px) {
            display: none !important;
          }
        }
        ul.slick-dots {
          @media (max-width: 1200px) {
            order: 3;
            margin-top: 23px;
          }

          li.dots-list {
            button {
              @media (max-width: 1200px) {
                width: 69px;
                height: 6px;
                background-color: #dedede;
              }
            }
            img.styletabimg {
              &.mobiletabimg {
                display: none;
              }
              @media (max-width: 1200px) {
                display: none;
                &.mobiletabimg {
                  position: absolute;
                  display: none;
                  top: -292px;
                }
              }
            }
            p {
              &.mobileTab {
                display: none;
                margin: 0px;
                @media (max-width: 1200px) {
                  position: absolute;
                  display: none;
                }
              }
              @media (max-width: 1200px) {
                display: none;
              }
            }
            @media (max-width: 1200px) {
              margin-left: 6px;
              margin-right: 6px;
            }
            &.slick-active {
              button {
                @media (max-width: 1200px) {
                  background-color: #5393fe;
                }
              }
              p.mobileTab {
                @media (max-width: 1200px) {
                  display: block;
                  position: absolute;
                  top: -290px;
                }
              }
              img.styletabimg.mobiletabimg {
                display: none;
                @media (max-width: 1200px) {
                  display: block;
                }
              }
            }
          }
        }
      }
      div.slick-list.draggable {
        @media (max-width: 1200px) {
          width: 313px;
          height: 245px;
        }
      }

      div {
        @media (max-width: 1200px) {
          //width: 360px;
          //height: auto;
          //align-items: center;
        }
        div.slick-list {
          div.slick-track {
            div.slick-active {
              img {
                @media (max-width: 1200px) {
                  width: 313px;
                  height: 223px;
                }
              }
            }
          }
        }
      }
    }
  }

  h2 {
    max-width: 808px;
    margin-bottom: 24px;
    font-size: 42px;
    @media (max-width: 1200px) {
      font-size: 32px;
      margin-bottom: 16px;
      width: 310px;
    }
  }
  p.styledSubtitle {
    text-align: center;
    //width: 684px;
    width: 812px;
    letter-spacing: 0.48px;
    @media (max-width: 1200px) {
      font-size: 16px;
      width: 270px;
      letter-spacing: 0.36px;
    }
  }

  article {
    display: flex;
    justify-content: center;
    //margin-top: 80px;
    margin-top: 72px;
    position: relative;
    @media (max-width: 1200px) {
      flex-direction: column;
      min-width: 100%;
      //margin-top: 64px;
      margin-top: 48px;
    }
    section.home-boxes-columns {
      margin-right: 46px;
      @media (max-width: 1200px) {
        //margin: 0 0 72px 0;
        margin: 0 0 48px 0;
        &:first-child {
          div {
            h3 {
              width: 180px;
            }
          }
        }
        &:last-child {
          div {
            h3 {
              width: 216px;
            }
          }
        }
      }
      &:last-child {
        margin: 0;
        //O padding da ultima div das caixas é diferente devido ao texto grande do titulo
        div {
          padding-left: 32px;
          padding-right: 32px;
          p {
            padding-right: 8px;
            padding-left: 8px;
          }
        }
      }
      div {
        background-color: #ffffff;
        color: $grayFont;
        padding: 48px 40px 64px 40px;
        width: 369px;
        height: 478px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 12px;
        box-shadow: 0 0 15px 0 $shadowColor;
        @media (max-width: 1200px) {
          margin: 0 auto;
          width: 312px;
          height: initial;
          padding: 48px 32px 64px 32px;
          p {
            width: 242px;
          }
        }
        img.home-boxes-columns-img {
          &-1 {
            width: 160px;
            height: 152px;
          }
          &-2 {
            width: 199px;
            height: 152px;
          }
          &-3 {
            width: 190px;
            height: 152px;
          }
        }
        h3 {
          text-align: center;
          margin: 24px 0 16px 0;
          width: 100%;
          color: $boxesTitleColor;
          font-weight: 800;
          font-size: 24px;
          letter-spacing: 0.55px;
          @media (max-width: 1200px) {
            margin-bottom: 16px;
            width: 180px;
          }
        }
        p {
          font-family: "Open Sans", sans-serif;
          line-height: 1.5;
          letter-spacing: 0.36px;
        }

        button {
          font-family: "Open Sans", sans-serif;
        }
      }
    }
  }
}
.img-change {
  display: none;
  background: transparent;
}
.top-carousel {
  display: flex;
  margin-top: 10px;
  img {
    filter: invert(51%) sepia(84%) saturate(1824%) hue-rotate(197deg)
    brightness(100%) contrast(103%);
  }
}

section.carousel-row {
  @extend .home-boxes-columns;
  display: flex;
  flex-direction: row;
}
section.home-meet-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-bottom: 150px;
  margin-bottom: 104px;
  @media (max-width: 1200px) {
    //margin-top: 129px;
    //margin-top: 40px;
    margin-top: 24px;
    //margin-bottom: 106px;
    margin-bottom: 90px;
  }
  article.home-meet-mobile-reverse-column {
    //margin-bottom: 183px;
    margin-bottom: 157px;
    @media (max-width: 1200px) {
      //margin-bottom: 128px;
      margin-bottom: 112px;
    }
  }
  article {
    width: 100%;
    display: flex;
    h3 {
      &.meet-mobile-title {
        margin-bottom: 24px;
        width: 344px;
      }

      &.meet-mobile-title {
        display: none;
      }
    }
    @media (max-width: 1200px) {
      margin: 0 0 184px 0;
      height: unset;
      min-width: 100%;
      flex-direction: column;
      align-items: center;
      &:last-child {
        margin-bottom: 0px;
      }

      &.home-meet-mobile-reverse-column {
        flex-direction: column-reverse;
      }
      h3.meet-mobile-title {
        color: $boxesTitleColor;
        display: block;
        //margin-bottom: 48px;
        //margin-bottom: 30px;
        margin-bottom: 24px;
        width: 100%;
      }
      div {
        //min-width: 100%;
        //display: flex;
        //flex-direction: column;
        //align-items: center;
        //text-align: center;
        /*&.home2-HYPE > div {
          min-width: 0%;
          display: block;
          align-items: initial;
          text-align: initial;
        }
        &.home2-HYPE > div > div {
          min-width: 0%;
          display: block;
          align-items: initial;
          text-align: initial;
        }*/
        h3.meet-desktop-title {
          display: none;
        }
      }
    }

    figure {
      display: flex;
      justify-content: flex-end;
      margin-left: 37px;
      &.main-image-2 {
        justify-content: flex-start;
        margin-left: 0px;
        margin-right: 122px;
        @media (max-width: 1200px) {
          margin-right: 0;
        }
      }
      div.home2-HYPE {
        width: 653px;
        height: 530px;
      }
      div.home3-HYPE {
        width: 484px;
        height: 483px;
      }
      img {
        object-fit: contain;
        &.home-meet-section-main-image-1 {
          width: 653px;
          height: 530px;
        }
        &.home-meet-section-main-image-2 {
          width: 484px;
          height: 483px;
        }
      }
      @media (max-width: 1200px) {
        position: relative;
        width: 360px;
        //margin: 0 0 48px 0;
        //margin: 0 0 32px 0;
        margin: 0 0 23px 0;
        display: flex;
        justify-content: center;

        div.home2-HYPE {
          //width: 330px;
          //height: 270px;
          width: 346px;
          height: 286px;
        }

        div.home3-HYPE {
          margin: 0 auto;
          width: 270px;
          //height: 254px;
          height: 270px;
          margin-top: 16px;
          margin-bottom: 17px;
        }

        img.home-meet-section-main-image-1 {
          width: 330px;
          height: 270px;
          margin: 0 auto;
        }
        img.home-meet-section-main-image-2 {
          margin: 0 auto;
          width: 254px;
          height: 254px;
        }
      }
    }

    section.home-meet-columns {
      div {
        width: 495px;
        @media (max-width: 1200px) {
          text-align: center;
          margin: 0;
          width: 100%;
        }
        h3 {
          color: $boxesTitleColor;
          margin-bottom: 24px;
        }
        p,
        h2 {
          text-align: left;
          @media (max-width: 1200px) {
            text-align: center;
            width: 312px;
            letter-spacing: 0.46px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.36px;
        }
        h2 {
          font-size: 42px;
          margin-bottom: 24px;
          @media (max-width: 1200px) {
            font-size: 32px;
            margin-bottom: 16px;
          }
        }
        a {
          margin-top: 64px;
          button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 32px;
            @media (max-width: 1200px) {
              width: 199px;
            }
          }
        }
      }
      figure {
        width: 100%;
      }
    }
  }
}
