section.email-main-title-container {
    text-align: center;
    max-width: 690px;
    margin: 88px 0 192px 0;
    align-self: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      max-width: 800px;
      margin: 16px 0 48px 0;
    }
    @media (max-width: 1200px) {
      width: 100%;
    margin: 72px 0 190px 0;
    h1{
      font-size: 36px;
    }
  }
}
  