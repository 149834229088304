$darkFont: #252a33;
$grayFont: #5C6473;
$whiteFont: #f0f1f2;
$mainBackgroundColor: #f9fafa;
$primaryColor: #2979ff;
$shadowColor: rgba(213, 213, 213, 0.30);

$hoverBlueButton: #2367d9;
$hoverGreenButton: #349974;
$hoverText: #5393FE;
$boxesTitleColor: #5393fe;

$buttonColor: #3caf85;
$buttonColorOpacity : #3caf85cc;

