section.plans-main-title-container {
  padding-left: 80px;
  max-width: 880px;
  align-self: flex-start;
  margin: 88px 0;

  p.styledSubtitle {
    width: 604px;
    letter-spacing: 0.48px;
  }
  @media (max-width: 1200px) {
    text-align: left;
    width: 312px;
    margin: 72px 0 88px 0;
    padding: 0;
    align-items: center;

    h1 {
      font-size: 36px;
      width: 312px;
      margin-bottom: 16px;
    }

    p.styledSubtitle {
      text-align: left;
      width: 312px;
    }
  }
}
