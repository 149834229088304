section.error-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 240px 0 190px 0;
  h1 {
    margin: 88px 0 24px 0;
  }
  p {
    max-width: 800px;
    text-align: center;
    margin-bottom: 48px;
  }
  @media (max-width: 1200px) {
    margin: 136px 0 184px 0;
    text-align: center;

    h1 {
      font-size: 34px;
    }
    p {
      font-size: 16px;
      width: 312px;
    }
    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
section.error-content {
  @media (max-width: 1200px) {
    img {
      width: 324px;
      height: 169px;
    }
  }
}
