header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  @media (max-width: 1200px) {
    width: 312px;
    height: 72px;
    margin-top: 0px;
  }
  a.header-snip-logo-container {
    display: inline-block;
    height: 40px;
    margin: 16px 0px;
    img.header-snip-logo {
      margin-right: 64px;
      width: 80px;
      height: 40px;
      @media (max-width: 1200px) {
        margin-right: 0px;
      }
    }
  }

  nav {
    display: flex;
    @media (max-width: 1200px) {
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 72px;
    }
    ul.menu-web {
      @media (max-width: 1200px) {
        display: none;
      }
      display: flex;
      list-style: none;
      align-items: center;
      a {
        margin-right: 32px;
        font-weight: 600;
        li {
          color: $grayFont;
          margin-bottom: 8px;

          &.header-hover {
            &:hover {
              color: #2979ff;
            }
          }
        }
      }

      div.dropdown {
        .dropdown {
          position: relative;
          display: inline-block;
        }
        div.header-product-container {
          margin-right: 32px;
          li {
            font-size: 16px;
            font-weight: 600;
            color: #5c6473;
            margin-bottom: 8px;
          }
        }
        div.dropdown-content {
          display: none;
          position: absolute;
          width: 406px;
          height: 170px;
          background-color: #f9fafa;
          box-shadow: 0 0 4px 0 rgba(213, 213, 213, 0.3);
          border: solid 1px #e4e5e6;
          border-radius: 3px;
          //box-shadow: 0 0 15px 0 $shadowColor;
          article.dropdown-article {
            padding-bottom: 24px;
            padding-top: 24px;
          }

          a {
            margin-left: 24px;
            margin-right: 24px;
            //margin-top: 32px;
            margin-top: 23px;
            //height: 88px;
            color: #5c6473;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.36px;
            &:hover {
              color: #2979ff;
              li {
                color: $primaryColor;
              }
              p {
                color: #2979ff;
              }
            }

            li {
              font-weight: 600;
            }
            p {
              font-size: 13px;
            }
          }
          a:first-child {
            margin-top: 0px;
          }
        }
        .header-product {
          cursor: default;
          display: flex;
          flex-direction: row;

          img.dropdown-arrow {
            width: 14px;
            height: 23px;
            margin-left: 8px;
            transform: none;
          }
        }
        &:hover {
          div.dropdown-content {
            display: flex;
            flex-direction: column;
            z-index: 2;

            /*&::before {
              content: "";
              position: absolute;
              top: -9px;
              left: 20px;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-bottom: 10px solid #f9fafa;
              box-shadow: 0 0 15px 0 $shadowColor !important;
            }*/
          }
          div.dropdown-content:after,
          div.dropdown-content:before {
            content: "";
            position: absolute;
            top: -11px;
            //left: 20px;
            left: 19px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            //border-bottom: 10px solid #f9fafa;
            border-bottom: 10px solid #e4e5e6;
            border-width: 11px;
          }

          div.dropdown-content:after {
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 10px solid #f9fafa;
            top: -9px;
            left: 20px;
            border-width: 10px;
          }

          img.dropdown-arrow {
            transform: rotateX(180deg);
          }
        }
      }
    }

    //Ícone Hamburguer
    label.hamburguer-icon-container {
      display: none;
      @media (max-width: 1200px) {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: -6px;
        img.hamburguer-icon {
          width: 28px;
          height: 22px;
        }
      }
    }
    input {
      display: none;
    }
  }
  div.login {
    display: flex;
    align-items: center;
    font-weight: 600;
    @media (max-width: 1200px) {
      display: none;
    }
    a {
      color: $primaryColor;
      letter-spacing: 0.36px;
      font-weight: 600;
      &:hover {
        color: $hoverBlueButton;
      }
      button.login-button {
        margin-left: 32px;
        background-color: $primaryColor;
        height: 44px;
        width: 164px;
        border-radius: 4px;
        border: none;
        font-size: 14px;
        font-weight: 600;
        &:hover {
          background-color: $hoverBlueButton;
        }
      }
    }
  }
}
