@import "bindings";

* {
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.svg-icon {
  width: 48px;
  height: 48px;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: $darkFont;
}

.svg-icon circle {
  stroke: $darkFont;
  stroke-width: 1;
}

a {
  display: inline-block;
}
html {
  height: 100%;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  overflow-x: hidden;
  @media (max-width: 1200px) {
    &.fullscreen-menu {
      overflow: hidden;
    }
  }
  body {
    font-family: "Open Sans", sans-serif;
    background-color: $mainBackgroundColor;
    color: $darkFont;
    min-height: 100%;
    font-size: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &.overflow-body {
      @media (max-width: 1200px) {
        overflow-x: hidden;
      }
    }
    ul.blue-bullet-list {
      li {
        position: relative;
        &:before {
          content: "•";
          display: inline-block;
          position: absolute;
          margin-left: -0.8em;
          top: -9px;
          left: auto;
          color: $boxesTitleColor;
          font-size: 30px;
        }
      }
    }
    nav.hamburguer-menu {
      position: fixed;
      background: #f9fafa;
      width: 100%;
      max-height: 100vh;
      padding: 24px;
      padding-top: 0px;
      display: flex;
      flex-direction: column;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      align-items: center;

      ul {
        list-style: none;
        width: 312px;
      }
      li {
        margin-bottom: 40px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        &.sub-hamburguer-li {
          margin-bottom: 32px;
        }
        input {
          display: none;
        }
        label.hamburguer-icon-container {
          img {
            //margin-left: 226px;
            margin-left: 228px;
          }
        }
        label.close-hamburguer-icon-container {
          display: flex;
          img {
            //margin-right: 12px;
            margin-right: 16px;
          }
        }
        p {
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.36px;
          text-align: center;
          display: inline;
          color: #5c6473;
          &.sub-hamburguer-title {
            display: block;
            text-align: justify;
            margin-bottom: 8px;
          }
          &.sub-hamburguer-text {
            font-weight: normal;
            margin-top: 8px;
          }
        }

        &:hover,
        :active {
          p,
          a {
            color: #2979ff;
          }
          p.submenu-tab-title {
            color: #5c6473;
          }
        }
      }

      div {
        align-self: center;
        justify-self: center;
        label {
          cursor: pointer;
          height: 22px;
        }
        input {
          display: none;
        }
      }
      div.nav-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        width: 312px;
        height: 72px;
        a {
          display: inline-block;
          height: 40px;
          img {
            width: 80px;
            height: 40px;
          }
        }
        .closed-icon {
          width: 22px;
          height: 22px;
        }
      }
      div.login {
        width: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
      }
      a {
        color: $grayFont;
        /*&:first-child {
          //margin-bottom: 16px;
        }*/
        button.button-green {
          width: 321px;
          &:hover,
          &:active {
            background-color: $hoverGreenButton;
          }
          &.blue {
            border-radius: 4px;
            color: $whiteFont;
            background-color: $primaryColor;
            width: 312px;
            &:hover,
            &:active {
              background-color: $hoverBlueButton;
            }
          }
        }
        button.button-clean {
          background-color: #f9fafa;
          border-radius: 28px;
          border: solid 1px $buttonColor;
          color: $buttonColor;
          margin-bottom: 0;
          width: 321px;
          &.blue {
            border-radius: 4px;
            color: $primaryColor;
            border-color: $primaryColor;
            width: 312px;
            border: 2px solid #2979ff;
            &.mobileClean {
              margin-bottom: 16px;
            }
            &:hover,
            &:active {
              background-color: $primaryColor;
              color: #f9fafa;
              //color: $hoverBlueButton;
            }
          }
        }
      }
      &.is-closed {
        transform: translateX(100%);
        display: none;
      }

      &.is-open {
        transform: translateX(0);
      }

      &.is-animating {
        animation-duration: 420ms;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
      }
      &.slide-in {
        animation-name: slidein;
      }

      &.slide-out {
        animation-name: slideout;
      }

      @keyframes slidein {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(0);
        }
      }

      @keyframes slideout {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(100%);
        }
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 800;
      font-family: "Fira Sans", sans-serif;
    }

    button {
      background-color: $buttonColor;
      font-family: "Open Sans", sans-serif;
      height: 56px;
      border-radius: 30px;
      border-color: transparent;
      width: 196px;
      color: $whiteFont;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      outline: none;
      &:active {
        background-color: $buttonColorOpacity;
      }
      &:hover,
      &:active {
        background-color: $hoverGreenButton;
      }
      &.oval-button {
        display: flex;
        color: #5393fe;
        width: 58px;
        height: 58px;
        padding: 18px 16px;
        background-color: transparent;
        border: solid 1px #5393fe;
        justify-self: center;
        align-items: center;
        align-self: center;
      }
      &.bigger-button {
        width: 228px;
      }
      &.blue-button {
        background-color: $primaryColor;
        &:hover,
        &:active {
          background-color: $hoverBlueButton;
        }
      }
    }
    .styledMainTitle {
      font-family: "Fira Sans", sans-serif;
      font-size: 55px;
      font-weight: 800;
      margin-bottom: 24px;
      @media (max-width: 1200px) {
        font-size: 36px;
      }
    }
    .styledTitle {
      text-align: center;
      font-family: "Fira Sans", sans-serif;
      font-size: 42px;
      font-weight: 800;
      margin-bottom: 24px;
    }
    .styledSubtitle {
      font-family: "Open Sans", sans-serif;
      font-size: 21px;
      color: $grayFont;
      @media (max-width: 1200px) {
        font-size: 16px;
      }
    }
    input.styledInput {
      width: 390px;
      height: 56px;
      border-radius: 30px;
      background-color: #edeff1;
      //padding: 0 40px;
      padding: 0 30px;
      border-color: transparent;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      color: $grayFont;
      font-weight: 600;
      margin-right: 2px;
      &:focus {
        outline: transparent;
      }
      &::-moz-placeholder {
        opacity: 1;
        color: $grayFont;
        font-weight: 400;
      }
      &::-webkit-input-placeholder {
        opacity: 1;
        color: $grayFont;
        font-weight: 400;
      }
    }
    div.paddingEx {
      @media (max-width: 1200px) {
        padding: 0 24px;
      }
    }
    div.retangle {
      max-width: 100%;
      min-width: 1160px;
      display: flex;
      flex-direction: column;
      position: relative;
      @media (max-width: 1200px) {
        max-width: 100%;
        min-width: 360px;
        //padding: 0 24px;
        align-items: center;
      }
    }
    div.firstContainer {
      max-width: 1200px;
      min-width: 1200px;
      display: flex;
      flex-direction: column;
      position: relative;
      @media (max-width: 1200px) {
        width: 360px;
        align-items: center;
        min-width: 360px;
      }

      @import "float-images";
      @import "header";

      @import "home-page-title";

      @import "product-page-title";

      @import "plans-page-title";

      @import "demo-page-title";

      @import "legal-page-title";

      @import "security-page-title";

      @import "email-page-title";

      @import "error404-page-title";

      @import "customers-page-title";

      main {
        width: 100%;

        @import "home-page-main";

        @import "product-page-meet";

        @import "plans-page-main";

        @import "demo-page-calendar";

        @import "error-page-main";

        @import "legal-page-main";

        @import "security-page-main";

        @import "email-page-main";

        @import "customers-page-main";

        @import "resources.scss";
      }
    }

    @import "joinFooter";
    @import "footer";
  }
}
