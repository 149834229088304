section.customers-title {
  margin: 88px 0px 136px 0px;
  position: relative;
  h1 {
    width: 522px;
    &.customers-us {
      width: 487px;
    }
  }
  p {
    width: 639px;
  }

  //mobile title
  @media (max-width: 1200px) {
    margin: 72px 0px;
    h1 {
      width: 312px;
      margin-bottom: 16px;
    }

    p {
      width: 304px;
      letter-spacing: 0.36px;
    }
  }
}
