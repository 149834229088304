.product-title {
  width: max-content;
  text-align: left;
  align-self: flex-start;
  padding-right: 48px;
  font-size: 34px;
}

.mobTitle {
  display: none;
}
.product-meet-article {
  &.background {
    &::before {
      content: "";
      position: absolute;
      background-color: #f4f4f5;
      width: 6000px;
      top: 1688px;
      //height: 574px;
      height: 508px;
      //margin-top: 76px;
      margin-top: 33px;
      z-index: -2;
      left: -200px;

      @media (max-width: 1200px) {
        //height: 676px;
        //height: 700px;
        //height: 656px;
        height: 642px;
        transform: translateX(-50%);
        left: 50%;
        //top: 63%;
        //top: 2492px;
        //margin-top: 17px;
        //margin-top: 32px;
        //margin-top: 37px;
        //margin-top: 22px;
        top: 2421px;
        margin-top: 0px;
      }
    }
  }

  &.en {
    &::before {
      @media (max-width: 1200px) {
        //top: 2477px;
        //top: 2340px;
        //height: 632px;
        top: 2302px;
        height: 619px;
      }
    }
  }
}
.product-container-subtitle {
  margin-left: 48px;
  font-size: 16px;
  width: 501px;
  line-height: 1.5;
  letter-spacing: 0.36px;
}

.carousel-left-side {
  margin-top: 56px;
  //margin-right: 112px;
  //margin-left: 21px;
  width: 501px;
}

.product-anchor {
  margin-left: 48px;
  margin-top: 48px;
  display: flex;
  color: #3caf85;
  text-decoration: underline;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  &:hover {
    color: #349974;

    img {
      filter: invert(48%) sepia(63%) saturate(390%) hue-rotate(107deg)
        brightness(94%) contrast(91%);
    }
  }

  img {
    margin-left: 24px;
  }
}

.carousel-product {
  width: 785px;
  height: 685px;
  position: relative;
  //top: -75px;
  top: -54px;
  left: 35px;
  @media (max-width: 1200px) {
    width: 549px;
    height: 400px;
    top: 0px;
    left: 0px;
    display: none;
  }
}
.alert-HYPE {
  width: 559px;
  height: 260px;
  @media (max-width: 1200px) {
    //width: 281px;
    //height: 130px;
    width: 311px;
    height: 160px;
  }
}
.mobHype {
  display: none;
  @media (max-width: 1200px) {
    display: block;
  }
}
.desktopHype {
  display: block;
  @media (max-width: 1200px) {
    display: none;
  }
}
.product-grid-article {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  //margin-top: 227px;
  margin-top: 176px;
  //margin-bottom: 289px;
  margin-bottom: 258px;
}
.grid-first-item {
  margin-right: 92px;
  //margin-top: -31px;
  margin-top: 5px;
}
.grid-second-item {
  position: relative;
}
section.product-meet-section {
  //margin-bottom: 99px;
  margin-bottom: 60px;
  position: relative;

  article.product-meet-item-start {
    align-items: flex-start;
  }

  article.product-meet-item {
    display: flex;
    justify-content: space-around;

    section.easy-to-use-text {
      margin-right: -62px;
    }
    section.storage-in-cloud {
      margin-right: 24px;
    }
    &:nth-child(1) {
      margin-top: 0px;
      //margin-bottom: 215px;
      //margin-bottom: 177px;
      //margin-bottom: 214px;
      //margin-bottom: 184px;
      margin-bottom: 174px;
      margin-right: 69px;
      @media (max-width: 1200px) {
        //margin-bottom: calc(128px + 88px);
        margin-bottom: 112px;
        margin-right: 0px;
      }
    }
    &:nth-child(2) {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-color: #f4f4f5;
        width: 6000px;
        top: -164px;
        height: 637px;
        margin-top: 76px;
        z-index: -2;
        left: -200px;

        @media (max-width: 1200px) {
          //height: 1078px;
          //top: -179px;
          //left: -433px;
          height: 980px;
          top: -115px;
        }
      }
    }
    &:nth-child(3) {
      margin: 0;
      //margin-top: 181px;
      //margin-top: 140px;
      //margin-top: 178px;
      //margin-top: 184px;
      //margin-top: 19px;
      //margin-top: 24px;
      //margin-bottom: 129px;
      margin-bottom: 13px;
      position: relative;
      top: -6px;
      @media (max-width: 1200px) {
        &.br {
          //margin-top: 166px;
          //margin-top: 174px;
          margin-top: 83px;
          //margin-top: 50px;
        }
        &.us {
          //margin-top: 209px;
          //margin-top: 176px;
          //margin-top: 113px;
          margin-top: 83px;
        }
      }
    }
    &.product-meet-item-reverse {
      flex-direction: row-reverse;
      /*margin-bottom: 138px;*/
      &::before {
        @media (max-width: 1200px) {
          height: 917px;
          top: -112px;
        }
      }
      &.br {
        &::before {
          content: "";
          position: absolute;
          background-color: #f4f4f5;
          width: 6000px;
          //height: 658px;
          height: 619px;
          z-index: -2;
          //top: -186px;
          //top: -148px;
          top: -138px;
          @media (max-width: 1200px) {
            //height: 1032px;
            //height: 1042px;
            //height: 1032px;
            height: 971px;
            top: -113px;
          }
        }
      }
      section {
        margin-left: 0;
        margin-right: 134px;
      }
    }
    figure {
      position: relative;
      &.figure-easy-to-use {
        top: 2px;
      }
      &.figure-in-the-cloud {
        margin-top: 34px;
      }
      div.product1-HYPE {
        //width: 634px;
        //height: 333px;
        width: 659px;
        height: 342px;
        @media (max-width: 1200px) {
          //width: 329px;
          //height: 185px;
          width: 345px;
          height: 185px;
        }
      }
      .produto2-HYPE {
        width: 478px;
        height: 418px;
        margin-right: -23px;
        @media (max-width: 1200px) {
          //width: 332px;
          width: 348px;
          height: 299px;
        }
      }
      .produto3-HYPE {
        width: 580px;
        height: 393px;
        margin-right: 87px;

        @media (max-width: 1200px) {
          //width: 291px;
          //height: 198px;
          width: 315px;
          height: 222px;
          min-width: initial;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        &.product-main-image {
          width: 634px;
          height: 333px;
          margin-right: 77px;
        }
        &.product-main-image2 {
          width: 478px;
          height: 418px;
          margin-right: -23px;
        }
        &.product-main-image3 {
          width: 580px;
          height: 393px;
          margin-right: 87px;
        }
        @media (max-width: 1200px) {
          &.product-main-image {
            width: 329px;
            height: 169px;
            min-width: initial;
            margin: 0;
          }
          &.product-main-image2 {
            width: 242px;
            height: 211px;
            min-width: initial;
          }
          &.product-main-image3 {
            width: 291px;
            height: 198px;
            min-width: initial;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    section {
      div {
        h2 {
          text-align: left;
          align-self: flex-start;
          padding-right: 48px;
          font-size: 34px;
          margin-bottom: 24px;
          width: 557px;
        }
        p {
          margin-left: 48px;
          //margin-bottom: 64px;
          font-size: 16px;
          width: 501px;
          line-height: 1.5;
          letter-spacing: 0.36px;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 32px;
          margin-left: 48px;
          margin-top: 48px;
          img {
            margin-left: 8px;
            width: 26px;
            height: 23px;
          }
        }
      }
    }
  }

  //MEDIA
  @media (max-width: 1200px) {
    //margin-bottom: 104px;
    margin-bottom: 90px;
    .grid-first-item {
      order: 3;
      margin: auto;
      //margin-top: 46px;
      margin-top: 25px;
    }
    .grid-second-item {
      order: 2;
    }
    .product-title {
      display: none;
    }
    .product-grid-article {
      display: flex;
      flex-direction: column;
      //margin-top: 144px;
      //margin-top: 126px;
      margin-top: 112px;
      //margin-bottom: 142px;
      margin-bottom: 129px;
    }

    .grid-article-figure {
      order: 2;
      img {
        width: 281px;
        height: 130px;
        margin-top: 24px;
      }
    }
    .product-container-subtitle {
      font-size: 16px;
      width: 312px;
      line-height: 1.5;
      letter-spacing: 0.36px;
      //margin-left: 0px;
      margin: auto;
    }
    .product-anchor {
      margin: auto;
      margin-top: 32px;
    }
    .mobTitle {
      width: 312px;
      //width: 324px;
      display: block;
      padding-right: 0px;
      text-align: center;
      align-self: auto;
      //margin-top: 32px;
      margin-top: 18px;

      &.know-resources {
        margin-top: 2px;
        text-align: initial;

        @media (max-width: 1200px) {
          margin-top: 8px;
        }
      }

      &.carousel-mob {
        margin-bottom: 24px;
        //margin-bottom: 0px;
        text-align: initial;
        //position: relative;
        //top: 44px;
        font-size: 26px;
      }
    }
    .carousel-left-side {
      padding: 0;
      margin: auto;
      margin-bottom: 3px;
      //width: 273px;
      width: 312px;
      position: relative;
      //top: -62px;
      top: 0px;
    }
    article.product-meet-item {
      flex-direction: column;
      align-items: center;
      &:last-child {
        h2 {
          width: 238px;
          margin-top: 22px;
        }
      }
      &.product-meet-item-reverse {
        section {
          margin: 0;
        }
      }
      &:nth-child(2) {
        flex-direction: column;
        &::before {
          content: "";
          position: absolute;
          background-color: #f4f4f5;
          width: 6000px;
          top: 1688px;
          height: 574px;
          margin-top: 76px;
          z-index: -2;
          left: -82px;

          @media (max-width: 1200px) {
            //height: 1078px;
            //top: -179px;
            //transform: translateX(-50%);
            //left: 50%;
            //height: 980px;
            height: 950px;
            top: -114px;
            transform: translateX(-50%);
            left: 50%;
          }
        }
        section {
          margin: 0;
        }
      }
      section {
        margin: 0;

        &.easy-to-use-text {
          margin: auto;
        }

        &.storage-in-cloud {
          margin: auto;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 100%;

          h2 {
            //margin-top: 40px;
            //margin-top: 48px;
            margin-top: 13px;
            margin-bottom: 16px;
            padding: 0;
            width: 273px;
            font-size: 26px;
            line-height: 1.15;
            letter-spacing: 0.59px;
          }
          p {
            padding: 0;
            margin-left: 0;
            margin-bottom: 64px;
            width: 273px;

            @media (max-width: 1200px) {
              margin: auto;
              width: 312px;
              margin-bottom: 0px;
            }
          }
          button {
            margin: 0;
            margin-top: 40px;
          }
        }
        figure {
          img {
            max-width: 330px;
            max-height: 168px;
            margin-top: 240px;
          }
        }
      }
    }
  }
}
