section.legal-main-section {
  display: flex;
  justify-content: space-between;
  //  margin-bottom: 64px;
  margin-bottom: 24px;

  @media (max-width: 1200px) {
    display: none;
  }

  div.legal-menu-container {
    width: 792px;

    @media (max-width: 1200px) {
      width: 335px;
    }

    h4 {
      margin-bottom: 32px;
      font-weight: 400;
      font-size: 21px;
      letter-spacing: 0.48px;
    }
    h2.legal-menu-title {
      font-size: 34px;
      margin-bottom: 32px;
      font-family: "Fira Sans", sans-serif;
      font-weight: 800;
    }

    h3 {
      margin-bottom: 16px;
      font-size: 34px;
      font-family: "Fira Sans", sans-serif;
      font-weight: 800;
      &.legal-menu-centralized-h3 {
        text-align: center;
      }
    }
    a {
      color: $primaryColor;
    }
    p {
      margin-bottom: 80px;
      letter-spacing: 0.36px;
      &.dataRequestText {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 16px;
      }
      &.legal-menu-inside-paragraph {
        margin-bottom: 20px;
      }
      em {
        font-weight: 700;
        font-style: normal;
        &.legal-menu-blue-text {
          color: $primaryColor;
        }
      }
      u {
        text-decoration: underline;
      }
      a {
        color: $primaryColor;
      }
      span {
        padding-left: 20px;
      }
    }
    ul.legal-menu-inside-list {
      padding-left: 40px;
      margin-bottom: 20px;
      margin-top: 0px;
      li {
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        color: $darkFont;
        list-style: disc;
        margin-bottom: 0px;
        font-weight: 400;
      }
    }
    ol.legal-menu-inside-list {
      padding-left: 40px;
      margin-bottom: 20px;
      margin-top: 0px;
      li {
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        color: $darkFont;
        list-style: decimal;
        margin-bottom: 0px;
        font-weight: 400;
      }
    }
    ol.nested {
      li {
        list-style: lower-roman;
      }
    }
    p ~ table {
      margin-top: -65px;
    }
    table {
      border: 1px solid #ddd;
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 25px;
      tr,
      th,
      td {
        padding: 5px;
        border: 1px solid black;
        border-collapse: collapse;
        vertical-align: top;
        min-width: 150px;
      }
      &.legal-table-spaced {
        tr,
        th,
        td {
          vertical-align: middle;
          padding: 20px 5.4px 50px 5.4px;
        }
      }
      tr {
        text-align: left;
      }
      em {
        font-weight: 700;
        font-style: normal;
        &.legal-menu-blue-text {
          color: $primaryColor;
        }
      }
    }
  }

  li {
    list-style: none;
    margin-bottom: 32px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    label {
      cursor: pointer;
    }
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
    li {
      counter-increment: lst-ctn-kix_list_2-0;
      ::before {
        content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
      }
      h3 {
        margin-bottom: 16px;
        font-size: 26px;
        font-family: "Fira Sans", sans-serif;
        font-weight: 600;
      }
    }
  }

  section.inactive {
    display: none;
  }
  li {
    list-style: none;
    //margin-bottom: 48px;
    margin-bottom: 24px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
  }
}

ul.item-blue-hover {
  input.legal-menu-input {
    display: none;
    &:checked + li {
      font-family: "Open Sans", sans-serif;
      color: #5393fe;
      font-weight: 700;
      font-size: 20px;
    }
  }
  li {
    color: #5c6473;
  }
  li:hover {
    color: #5393fe;
  }
}

div.legal-menu {
  .terms-title {
    margin-bottom: 32px;
  }
}

/* @media (max-width: 1200px) {
  p,
  h3 {
    display: none;
  }
} */
.dataRequestText {
  width: 792px;
  height: 96px;
  margin: 22px 0 48px !important;
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.48px;
  color: #252a33;
}

// Configurações da animação do spinner

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
  margin: auto;
  display: block;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #2979ff;
  }
  25% {
    stroke: #2979ff;
  }
  50% {
    stroke: #2979ff;
  }
  75% {
    stroke: #2979ff;
  }
  100% {
    stroke: #2979ff;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

.hideIframe {
  display: none;
}
.legalIframe {
  height: 568px;
  width: 593px;
  border: none;
}
.iframeContainer {
  height: 568px;
  width: 593px;
  display: flex;
  @media (max-width: 1200px) {
    height: 700px;
    border: none;
    width: 312px;
  }
}

.legalIframeMobile {
  height: 700px;
  border: none;
  width: 312px;
}

section.legal-accordion-menu {
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
}

// CONFIGURAÇÃO

section.legal-accordion-menu {
  //padding: 0 80px;
  @media (max-width: 1200px) {
    padding: 0;
    margin-bottom: 90px;
  }

  article {
    .accordionButton:checked + .legal-accordion-menu-container {
      p {
        opacity: 1;
        max-height: 100%;
        margin-bottom: 60px;
        padding: 0 24px;
        width: 360px;

        &.form-subtext {
          margin-bottom: 48px;
        }

        &.legal-menu-inside-paragraph {
          margin-bottom: 20px;
        }
        em {
          font-weight: 700;
          font-style: normal;
          &.legal-menu-blue-text {
            color: $primaryColor;
          }
        }
        u {
          text-decoration: underline;
        }
        a {
          color: $primaryColor;
        }
        span {
          padding-left: 20px;
          width: 312px;
        }
      }

      strong {
        font-family: "Fira Sans", sans-serif;
        font-weight: 600;
        font-size: 26px;
        color: #252a33;
      }
      img.accordion-menu-expand-arrow {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
        z-index: 99999;
        width: 23px;
        height: 14px;
        filter: saturate(100%) brightness(0) invert(46%) sepia(91%)
          saturate(1614%) hue-rotate(200deg) brightness(105%) contrast(99%);
      }
      h3 {
        color: #5393fe;
        font-weight: bold;
        width: 283px;
      }
      h4 {
        display: flex;
        margin: 32px 0 32px 0;
        width: 312px;
        text-align: left;
        //padding: 0 24px;
        font-weight: normal;

        @media (max-width: 1200px) {
          margin-top: 24px;
        }
      }
      .last-paragraph {
        margin-bottom: 128px;
      }
      .menu-legal-mobile-title {
        //margin-top: 24px;
      }

      div.legal-accordion-menu-title {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding: 0;
        background-color: #f9fafa;
        width: 100%;
      }
      #divheader1.active {
        //box-shadow: 0 12px 8px -6px #51596620;
        &::after {
          content: " ";
          height: 100%;
          position: absolute;
          left: -25%;
          top: 0;
          width: 150%;
          box-shadow: 0 12px 8px -6px #51596620;
          z-index: -1;
        }
      }
      #divheader2.active {
        //box-shadow: 0 12px 8px -6px #51596620;
        &::after {
          content: " ";
          height: 100%;
          position: absolute;
          left: -25%;
          top: 0;
          width: 150%;
          box-shadow: 0 12px 8px -6px #51596620;
          z-index: -1;
        }
      }
      #divheader3.active {
        //box-shadow: 0 12px 8px -6px #51596620;
        &::after {
          content: " ";
          height: 100%;
          position: absolute;
          left: -25%;
          top: 0;
          width: 150%;
          box-shadow: 0 12px 8px -6px #51596620;
          z-index: -1;
        }
      }
      #divheader4.active {
        //box-shadow: 0 12px 8px -6px #51596620;
        &::after {
          content: " ";
          height: 100%;
          position: absolute;
          left: -25%;
          top: 0;
          width: 150%;
          box-shadow: 0 12px 8px -6px #51596620;
          z-index: -1;
        }
      }

      .mobile-terms,
      .mobile-security {
        margin-bottom: 52px;
      }

      .mobile-terms,
      .mobile-privacity,
      .mobile-security {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        &.mobile-form {
          margin-top: 24px;
        }

        a {
          color: #5393fe;
        }
        .mobile-privacity-div-intern {
          h3 {
            padding: 0 24px;
          }
        }
        p ~ table {
          margin-top: -45px;
        }
        table {
          border: 1px solid #ddd;
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 25px;
          tr,
          th,
          td {
            padding: 5px;
            border: 1px solid black;
            border-collapse: collapse;
            vertical-align: top;
            font-size: 14px;
          }
          &.legal-table-spaced {
            tr,
            th,
            td {
              vertical-align: middle;
              padding: 20px 5.4px 50px 5.4px;
            }
          }
          tr {
            text-align: left;
          }
          em {
            font-weight: 700;
            font-style: normal;
            &.legal-menu-blue-text {
              color: $primaryColor;
            }
          }
        }
        h3 {
          color: #434343;
          font-weight: 700;
          font-size: 26px;
          margin-bottom: 24px;
          align-self: start;
        }

        li {
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          color: #5c6473;
          width: 312px;
          //padding: 0 24px;
          list-style: disc;
        }
        ul {
          padding-left: 24px;
        }
      }
    }

    .accordionButton:not(:checked) + .legal-accordion-menu-container {
      img.accordion-menu-expand-arrow {
        transform: rotate(0deg);
        transition: transform 0.3s ease;
        width: 23px;
        height: 14px;
        filter: grayscale(100%);
      }
      h3 {
        font-weight: normal;
        width: 283px;
        color: $grayFont;
      }
      h4 {
        display: none;
      }
      p {
        display: none;
      }
      div.legal-accordion-menu-title {
        position: static;
        margin: 0;
        //top:0;
        box-shadow: 0;
      }

      .mobile-terms,
      .mobile-privacity,
      .mobile-security {
        display: none;
      }
    }

    input {
      display: none;
    }

    div.legal-accordion-menu-container {
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: #f9fafa;

      div.legal-accordion-menu-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 90px;
        h3 {
          font-size: 24px;
        }
        img {
          width: 23px;
          cursor: pointer;
        }
      }
      p {
        // overflow: hidden;
        color: $grayFont;
        padding: 24px 78px 0 40px;
        max-height: 0px;
        transition: all 0.4s ease;
      }
    }
    @media (max-width: 1200px) {
      width: 100%;
      width: 100%;
      //margin: 5px 0 144px 0;
      margin: 5px 0 0 0;
      padding: 0;

      div.legal-accordion-menu-container {
        transition: max-height 0.3s ease-in-out;
        div.legal-accordion-menu-title {
          width: 100%;
          h3 {
            font-size: 20px;
            font-weight: bold;
            font-family: "Open Sans", sans-serif;
          }
          img {
            height: 50px;
            object-fit: none;
          }
        }
        p {
          padding: 0 24px 0 0;
          margin-bottom: 32px;
          //overflow: hidden;
          pointer-events: none;
          max-height: 0;
        }
      }
    }
  }
}
