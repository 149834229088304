section.error-section {
  text-align: center;
  margin-top: 88px;
  margin-bottom: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 752px;
  }
  button {
    margin-top: 48px;
  }
  @media (max-width: 1200px) {
    margin-top: 72px;
    margin-bottom: 80px;
    h1 {
      font-size: 36px;
    }
    p {
      width: 312px;
    }
  }
}

section.error-content {
  margin: 0 auto;
  margin-bottom: 144px;
  align-items: center;
  .erro-HYPE {
    width: 810px;
    height: 423px;
    @media (max-width: 1200px) {
      width: 324px;
      height: 169px;
    }
  }
}
