section.product-main-title-container {
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 88px 0 200px 0;
  h1 {
    width: 694px;
  }
  p {
    width: 880px;
    color: $grayFont;
    line-height: 1.52;
    letter-spacing: 0.48px;
  }
  @media (max-width: 1200px) {
    text-align: left;
    width: 100%;
    //margin: 72px 0 136px 0;
    margin: 72px 0 124px 0;
    h1 {
      font-size: 36px;
      width: 312px;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      width: 312px;
    }
  }
}
