section.home-main-title-container {
  margin: 88px 0 192px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    max-width: 691px;
    margin: 0;
    margin-bottom: 24px;
  }
  p {
    width: 604px;
    margin: 0 0 48px 0;
    line-height: 1.52;
    letter-spacing: 0.48px;
  }
  a {
    color: #f9fafa;
  }

  img.ilustration-home {
    margin-top: 64px;
    width: 1200px;
    height: 679px;

    @media (max-width: 1200px) {
      object-fit: contain;
      position: relative;
      left: 385px;
      margin-top: 44px;
    }
  }
  @media (max-width: 1200px) {
    width: 312px;
    //margin: 72px 0 152px 0;
    margin: 72px 0 129px 0;
    h1 {
      font-size: 36px;
      margin-bottom: 16px;
      width: 312px;
    }

    input {
      width: 100%;
      margin-bottom: 16px;
    }
    button {
      width: 100%;
    }
    p {
      width: 285px;
      letter-spacing: 0.36px;
    }
  }
}
