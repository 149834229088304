&.customers-main {
  p,
  li {
    color: $grayFont;
  }
  h2.customers-h2-title {
    font-family: "Fira Sans", sans-serif;
    font-weight: 800;
    font-size: 34px;
    //font-size: 34pt;
    line-height: 1.12;
    letter-spacing: 0.77px;
    margin-bottom: 24px;

    @media (max-width: 1200px) {
      font-size: 32px;
      margin-bottom: 16px;
    }
  }
}

section.customers-menu-desktop {
  //margin-bottom: 216px;
  margin-bottom: 200px;
  position: relative;
  @media (max-width: 1200px) {
    display: none;
  }
  h2 {
    font-family: "Fira Sans", sans-serif;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.36px;
    margin-bottom: 40px;
    color: $boxesTitleColor;
  }

  article.menu-desktop {
    display: flex;
    nav.menu-selector {
      margin-right: 80px;

      input {
        display: none;
      }
      input.menu-desktop-input:checked + label div img {
        display: block;
      }

      input.menu-desktop-input:checked + label div h3 {
        color: $boxesTitleColor;
        font-weight: bold;
      }
      label {
        div {
          display: flex;
          cursor: pointer;
          h3 {
            font-weight: normal;
            line-height: 1.6;
            letter-spacing: 0.45px;
            color: $grayFont;
            font-family: "Open Sans", sans-serif;
            font-size: 20px;
            width: 273px;
            margin: 0 30px 24px 0;
            &:hover {
              color: $boxesTitleColor;
            }
          }
          img {
            width: 26px;
            height: 22px;
            margin-top: 7px;
            display: none;
          }
        }
      }
    }
    div.menu-content-desktop {
      h3 {
        font-family: "Fira Sans", sans-serif;
        font-size: 26px;
        font-weight: 800;
        line-height: 1.15;
        letter-spacing: 0.59px;
        margin-bottom: 16px;
      }
    }
  }
}

section.customers-menu-mobile {
  display: none;
  @media (max-width: 1200px) {
    width: 100%;
    display: block;
    //margin-bottom: 184px;
    margin-bottom: 168px;
    h2 {
      font-family: "Fira Sans", sans-serif;
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 0.36px;
      margin-bottom: 16px;
      color: $boxesTitleColor;
    }

    article.menu-accordion-container-mobile {
      div {
        input {
          display: none;
          &:checked ~ div.menu-content {
            height: auto;
            @media (max-width: 1200px) {
              margin-bottom: 60px;
              padding-top: 8px;
            }
          }

          &:checked ~ label h3 {
            color: $boxesTitleColor;
            font-weight: 700;
          }

          //Se mudarmos o bg color do span, conseguimos mudar a cor do sVG
          &:checked ~ label span {
            background-color: $boxesTitleColor;
            transform: rotateZ(180deg);
          }

          &:checked ~ label ~ div.menu-last-margin {
            //margin-bottom: 220px;
            margin-bottom: 168px;
          }
        }

        label {
          display: flex;
          height: 104px;
          align-items: center;
          justify-content: space-between;
          h3 {
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.6;
            letter-spacing: 0.46px;
            color: $grayFont;
            //font-weight: normal;
            width: 273px;
          }
          //O mask serve para trocar a cor do SVG quando menu tiver aberto
          span {
            &.menu-mobile-icon {
              width: 22px;
              height: 14px;
              background-color: $grayFont;
              mask: url(../images/new/misc/ic-expand.svg) center center;
              mask-size: cover;
              -webkit-mask: url(../images/new/misc/ic-expand.svg) center center;
              -webkit-mask-size: cover;
              margin-bottom: 29px;
            }
            &.tiny-text {
              margin-bottom: -2px;
            }
          }
        }

        div.menu-content {
          height: 0px;
          overflow: hidden;
          &.menu-last-margin {
            //margin-bottom: 196px;
            margin-bottom: 168px;
          }
        }
      }
    }

    figure {
      position: relative;
    }
  }
}

//Menu hibrido tanto pro mobile quanto pro desktop
div.menu-content,
div.menu-content-desktop {
  display: flex;
  flex-direction: column;
  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.46px;
    margin-bottom: 32px;
  }

  p {
    line-height: 1.5;
    letter-spacing: 0.36px;
    margin-bottom: 48px;
  }

  div.menu-highlights-container {
    background-color: #e3ebfa;
    padding: 24px 32px 16px 32px;
    border-radius: 9px;
    margin-bottom: 56px;
    h5 {
      font-family: "Fira Sans", sans-serif;
      font-size: 16px;
      font-weight: 800;
      line-height: 1.75;
      letter-spacing: 0.37px;
      margin-bottom: 16px;
    }
    ul {
      margin-left: 24px;
      list-style: none;
      li {
        margin-bottom: 16px;
        line-height: 1.5;
        letter-spacing: 0.36px;
      }
    }
  }
  a {
    margin-left: auto;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 26px;
        height: 23px;
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 1200px) {
    h4 {
      //margin-bottom: 24px;
      margin-bottom: 16px;
    }
    div.menu-highlights-container {
      h4 {
        margin-bottom: 24px;
      }
      padding: 24px 32px 32px 32px;
      ul {
        margin-left: 24px;
        li {
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

div.inactive {
  display: none;
}

section.customers-boxes {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-bottom: 216px;
  margin-bottom: 200px;
  position: relative;
  h2 {
    width: 755px;
  }

  p {
    width: 621px;
    line-height: 1.52;
    letter-spacing: 0.48px;
    //margin-bottom: 80px;
    margin-bottom: 70px;
  }

  article.boxes-container {
    display: flex;
    justify-content: center;
    div {
      background-color: white;
      box-shadow: 0 0 15px $shadowColor;
      margin-right: 40px;
      padding: 48px 40px 64px 40px;
      border-radius: 12px;
      width: 369px;
      &:last-child {
        margin: 0;
      }

      figure {
        margin-bottom: 24px;
        img.customers-boxes-image {
          &-1 {
            width: 195px;
            height: 155px;
          }
          &-2 {
            width: 221px;
            height: 155px;
          }
          &-3 {
            width: 213px;
            height: 155px;
          }
        }
      }

      h3 {
        font-size: 26px;
        line-height: 1.15;
        letter-spacing: 0.59px;
        color: $boxesTitleColor;
        margin-bottom: 24px;
      }

      ul {
        text-align: left;
        margin-left: 24px;
        list-style: none;
        li {
          font-family: "Open Sans", sans-serif;
          line-height: 1.5;
          margin-bottom: 24px;

          @media (max-width: 1200px) {
            font-size: 16px;
            letter-spacing: 0.36px;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  //mobile styles
  @media (max-width: 1200px) {
    //margin-bottom: 200px;
    margin-bottom: 168px;
    h2 {
      width: 312px;
    }

    p {
      width: 312px;
      margin-bottom: 72px;
      letter-spacing: 0.36px;
    }
    article.boxes-container {
      flex-direction: column;
      margin-bottom: 0px;
      div {
        margin: 0 0 72px 0;
        width: 312px;
        padding: 48px 32px 64px 32px;
        h3 {
          line-height: 1.5;
          letter-spacing: 0.36px;
          margin-bottom: 16px;
        }

        ul {
          margin-left: 24px;
        }
      }
    }
  }
}

section.customers-features {
  //margin-bottom: 216px;
  margin-bottom: 195px;
  position: relative;
  h2 {
    width: 512px;
  }
  p.features-main-subtitle {
    width: 767px;
    line-height: 1.5;
    letter-spacing: 0.36px;
    margin-bottom: 72px;
  }

  article.features-illustrated {
    display: flex;
    figure.features-illustration-container-desktop {
      padding-top: 48px;
      margin-right: 120px;
      position: relative;
      img.features-illustration-image-desktop {
        width: 606px;
        height: 365px;
      }
    }

    figure.features-illustration-container-mobile {
      display: none;
    }

    div {
      h3 {
        font-size: 26px;
        line-height: 1.15;
        letter-spacing: 0.59px;
        margin-bottom: 16px;
      }

      p {
        margin-bottom: 56px;
        line-height: 1.5;
        letter-spacing: 0.36px;
      }
    }
  }
  //Estilos Mobile
  @media (max-width: 1200px) {
    //margin-bottom: 128px;
    margin-bottom: 88px;
    h2.customers-h2-title {
      width: 312px;
      font-size: 26px;
      margin-bottom: 16px;
    }
    p.features-main-subtitle {
      width: 312px;
      font-size: 16px;
      letter-spacing: 0.36px;
      margin-bottom: 56px;
    }
    article.features-illustrated {
      flex-direction: column;
      div {
        //margin-bottom: 128px;
        margin-bottom: 80px;
        h3 {
          font-size: 21px;
          line-height: 1.5;
          letter-spacing: 0.36px;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
      figure.features-illustration-container-desktop {
        display: none;
      }
      figure.features-illustration-container-mobile {
        display: block;
        position: relative;
        img.features-illustration-image-mobile {
          width: 309px;
          height: 186px;
        }
      }
    }
  }
}

section.customers-customization {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 216px;
  position: relative;
  h2 {
    width: 958px;
  }

  p {
    width: 920px;
    margin-bottom: 72px;
  }

  div.temporary-div {
    width: 971px;
    height: 386px;
    background: #e3ebfa;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}
