section.legal-main-title-container {
  display: flex;
  justify-content: space-between;
  margin: 88px 0 99px 0;
  //padding: 0 24px;
  //z-index: 999999;

  p {
    max-width: 486px;
    letter-spacing: 0.48px;
  }

  @media (max-width: 1200px) {
    width: 360px;
    margin: 72px 0 72px 0;
    padding: 0 24px;
    h1 {
      font-size: 36px;
      width: 312px;
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 0;
      letter-spacing: 0.36px;
    }
  }

  img.legal-title-img {
    width: 398px;
    height: 335px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
}
