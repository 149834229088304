.security-main {
  h2.styled-security-title {
    font-family: "Fira Sans", sans-serif;
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 72px;
    @media (max-width: 1200px) {
      font-size: 32px;
      margin-bottom: 56px;
    }
  }
}

section.security-main-title-container {
  margin: 88px 0 144px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    color: #5c6473;
    font-weight: 400;
    margin-bottom: 128px;
    max-width: 849px;
    letter-spacing: 0.48px;
  }
  div.security1-HYPE {
    width: 807px;
    height: 429px;
  }
  img.ilustration-security {
    width: 807px;
    height: 429px;
  }

  @media (max-width: 1200px) {
    align-items: center;
    width: 100%;
    margin: 72px 0 142px 0;
    padding: 0;

    h1 {
      text-align: left;
      font-size: 36px;
      width: 312px;
      line-height: 1.11;
      letter-spacing: 0.82px;
      margin-bottom: 16px;
    }

    p {
      text-align: left;
      margin-bottom: 94px;
      font-size: 16px;
      width: 312px;
      line-height: 1.5;
      letter-spacing: 0.36px;
    }
    div.security1-HYPE {
      width: 324px;
      height: 174px;
    }
    img.ilustration-security {
      width: 324px;
      height: 174px;
    }
  }
}
