section.joinSection {
  //height: 596px;
  height: 602px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 1200px) {
    padding: 184px 0 220px 0;
  }

  &.join-section-security {
    //padding-top: 26px;
    padding-top: 100px;
    height: 522px;
  }

  img.float-images-footer-6 {
    position: absolute;
    object-fit: contain;
    bottom: -49px;
    left: 918px;
    @media (max-width: 1200px) {
      width: 362px;
      height: 334px;
      //bottom: -249px;
      bottom: -209px;
      left: 146px;
    }
  }

  div.wave-background-mobile {
    position: absolute;
    width: 100%;
    top: -107px;
    left: 0px;
    transform: rotateY(180deg);

    svg {
      display: block;
      margin-top: -2.5rem;
      width: 100%;
      height: 20rem;
    }
  }
  div.join-section-container {
    width: 1200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //margin-top: 40px;
    top: -20px;

    &.ask-demo-container {
      @media (max-width: 1200px) {
        margin-top: 0px;
        position: absolute;
        top: 184px;
      }
    }

    &.ask-demo-container > p {
      width: 874px;
      letter-spacing: 0.48px;

      @media (max-width: 1200px) {
        letter-spacing: 0.36px;
      }
    }

    @media (max-width: 1200px) {
      margin: 0px;
    }

    h2,
    p {
      color: $whiteFont;
    }
    a {
      color: #f9fafa;
    }
    h2 {
      max-width: 840px;
      text-align: center;
      margin-bottom: 24px;
      margin-top: 8px;
      @media (max-width: 1200px) {
        margin-top: 0px;
        font-size: 42px;
        width: 312px;
      }
    }

    p.banner-security-subtitle {
      font-family: "Open Sans", sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.52;
      letter-spacing: 0.48px;
      width: 874px;

      @media (max-width: 1200px) {
        font-size: 16px;
        letter-spacing: 0.36px;
      }
    }
    p {
      width: 1016px;
      text-align: center;
      margin-bottom: 48px;
      @media (max-width: 1200px) {
        font-size: 16px;
        max-width: 312px;
      }
      a.footer-link {
        text-decoration: underline;
        font-weight: 600;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      z-index: 1;
      margin-bottom: 8px;
      @media (max-width: 1200px) {
        margin-bottom: 0px;
      }
      input.styledInput {
        width: 328px;
        @media (max-width: 1200px) {
          width: 312px;
        }
      }
      button {
        margin-top: 16px;
        width: 328px;
        @media (max-width: 1200px) {
          width: 312px;
        }
      }
    }
  }
  div.banner-background-color {
    position: absolute;
    width: 100%;
    background-color: #2979ff;
    height: 100%;
    z-index: -100;
    top: 164px;

    &.security {
      background-color: #252a33;
      top: 158px;
    }
  }

  &-customers {
    height: unset;
    padding-top: 128px;
    //padding-bottom: 144px;
    padding-bottom: 100px;
    @media (max-width: 1200px) {
      padding-top: 95px;
      padding-bottom: 108px;
    }
    div.wave-background-mobile-customers {
      top: -142px;
      svg {
        display: block;
        margin-top: -2.5rem;
        width: 100%;
        height: 20rem;
      }
    }
    button {
      width: 328px;
      height: 52px;
      background-color: $mainBackgroundColor;
      color: $buttonColor;
      &:hover {
        color: $hoverGreenButton;
      }
    }
    div.customers-banner-background-color {
      background-color: $buttonColor;
      top: 135px;
    }
  }
  @media (max-width: 1200px) {
    height: unset;
    &.join-section-home {
      //height: 788px;
      //height: 809px;
      height: 768px;
      position: relative;
    }

    &.join-section-security {
      //padding-bottom: 139px;
      padding: 184px 0 220px 0;
      padding-bottom: 29px;
      height: unset;
      margin-top: 0;
      div.join-section-container {
        margin-top: 0px;
        position: relative;
        top: -49px;
      }
      div.wave-background-mobile {
        top: -121px;
      }
    }
    margin-top: 0;
    div.join-section-container {
      width: 360px;
      margin-top: 39px;
      //margin-top: 6px;
      //position: absolute;
      h2 {
        padding: 0 24px;
        margin-bottom: 16px;
        &.security-banner-title {
          padding: 0px;
        }
      }
      p {
        width: 100%;
        margin-bottom: 48px;
        letter-spacing: 0.36px;
      }
    }
  }
}
