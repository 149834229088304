footer {
  background-color: $darkFont;
  color: #f9fafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
  section.footer-nav-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $darkFont;
    height: 100%;
    width: 1200px;
    padding-top: 12px;
    margin-bottom: 88px;
    margin-top: 101px;
    &.securityFooter {
      margin-top: 13px;
    }
    figure.footer-snip-logo {
      margin-right: 64px;
      height: 40px;
      a {
        height: 40px;
        display: inline-block;
        img {
          width: 80px;
          height: 40px;
        }
      }
    }
    nav {
      display: flex;
      width: 100%;
      h2.footer-menu-title {
        margin-bottom: calc(
          22px - 12px
        ); //22px de margin bottom - 12px do padding do item interno
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.36px;
      }
      ul {
        margin-right: 16px;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 180px;
        li.footer-menu-item {
          white-space: nowrap;
          font-size: 13px;
          letter-spacing: 0.3px;
          a {
            color: $whiteFont;
            display: inline-block;
            padding: 12px 0;
          }
        }
      }
      div.social-media-container {
        margin-left: auto;
        div.social-media-items {
          margin-bottom: 37px;
          height: 32px;
          a {
            margin-right: 28px;
            img {
              width: 32px;
              height: 32px;
            }
            &:nth-child(2) img {
              height: 31px;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
        div.app-store-container {
          figure.app-store-items {
            display: flex;
            justify-content: flex-end;
            a {
              height: 40px;
              img.app-store-item-google {
                width: 136px;
                height: 40px;
                border-radius: 0;
                background-color: $darkFont;
              }
            }
          }
        }
      }
    }
  }
  section.footer-copyright-and-location-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
    select.footer-select-localization {
      display: block;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      color: $whiteFont;
      padding: 8px 0 8px 8px;
      width: 153px;
      box-sizing: border-box;
      margin: 0;
      border: 1px solid $whiteFont;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: $darkFont;
      // background-image: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
      background-image: url(../images/new/footer/resume.svg);
      background-repeat: no-repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 18px;
      margin-bottom: 32px;
    }
    p.footer-copyright {
      max-width: 451px;
      font-size: 12px;
      text-align: center;
      @media (max-width: 1200px) {
        margin-top: 0px;
        width: 269px;
        font-size: 10px;
      }
    }
  }

  @media (max-width: 1200px) {
    & {
      section.footer-copyright-and-location-section {
        width: 100%;
        padding: 0px 24px 32px 24px;
      }
    }
    section.footer-nav-section {
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      min-width: 360px;
      max-width: 360px;
      width: 360px;
      align-items: center;
      margin: 0 0 80px 0;
      padding: 80px 24px 0 24px;
      figure.footer-snip-logo {
        width: 100%;
        margin: 0 0 64px 0;
      }
      nav {
        flex-direction: column;
        height: 100%;
        width: 100%;
        margin-top: 0;
        div {
          ul {
            margin: 0;
            margin-bottom: calc(
              48px - 12px
            ); //48px de margin bottom - 12px do padding do item interno
            width: 100%;
          }
          &:nth-child(3) {
            margin-bottom: calc(
              80px - 12px
            ); //80px de margin bottom - 12px do padding do item interno
          }
        }

        div.social-media-container {
          margin-top: 0px;
          width: 100%;
          div.social-media-items {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-bottom: 32px;
            align-items: center;
            a {
              margin-right: 24px;
              img {
                width: 32px;
                height: 32px;
                margin: 0;
              }
            }
          }
        }
        div.app-store-container {
          figure.app-store-items {
            justify-content: center !important;
          }
        }
      }
    }
  }
}
