.resources-main-title {
  h1 {
    width: 500px;
  }

  h1.us-main-title {
    width: 578px;
  }
  width: 657px;
  //margin-top: 78px;
  margin-top: 88px;
}

.resources-second-main-title {
  margin: auto;
  //margin-top: 85px;
  margin-top: 97px;
  h1,
  p {
    text-align: center;
  }

  p.grid-subtitle {
    //width: 1090px;
    width: 880px;
    margin: auto;
    letter-spacing: 0.48px;
  }
}

.grid-title {
  @media (max-width: 1200px) {
    font-size: 32px;
    margin-bottom: 16px;
  }
}

.spacing {
  letter-spacing: 0.48px;
  width: 639px;

  @media (max-width: 1200px) {
    width: auto;
  }
}

.grid-container {
  position: relative;

  &.collab-container {
    margin-top: 86px;
  }
  div.mobile {
    display: none;
  }
  div.fade {
    width: 100%;
    height: 227px;
    background-image: linear-gradient(
      to bottom,
      rgba(249, 250, 250, 0) 50%,
      #f9fafa 107%
    );
    position: absolute;
    top: 262px;
  }
  div.fade-collab {
    display: none;
  }
  div.fade-structure {
    display: none;
  }
  ul.resources-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: hidden;
    height: 432px;
    &.collab-mobile {
      height: auto;
    }
    &.structure-mobile {
      height: auto;
    }
    &.custom-mobile {
      height: auto;
    }
    &.grid-more {
      height: auto;

      div.fade {
        display: none;
      }
    }
    li.grid-cell {
      width: 256px;
      margin-bottom: 40px;
      width: 377px;
      list-style: none;
      display: flex;
      flex-direction: row;
      margin-right: 31px;
      img.cell-img {
        margin-right: 16px;
        box-shadow: 0 0 15px 0 $shadowColor;
      }
      h4.cell-title {
        font-family: "Fira Sans", sans-serif;
        font-size: 16px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.37px;
        color: #252a33;
        text-transform: uppercase;

        @media (max-width: 1200px) {
          text-transform: uppercase;
        }
      }
      p.cell-text {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.36px;
        color: #5c6473;
      }
    }
  }
}

.resources-first-grid {
  display: flex;
  flex-direction: column;
  .first-grid-cell {
    position: relative;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-top: 113px;

    h3.flex-soon {
      font-family: "Fira Sans", sans-serif;
      font-size: 16px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.36px;
      color: #5393fe;
      margin-bottom: 16px;
    }

    h2.flex-item-title {
      font-family: "Fira Sans", sans-serif;
      font-size: 32px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.12;
      letter-spacing: 0.77px;
      color: #252a33;

      &.make-docs {
        width: 360px;
      }

      &.us-connect-collection {
        width: 318px;
      }
    }
    p.flex-item-text {
      width: 384px;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.36px;
      color: #5c6473;
      margin-top: 24px;

      &.last-flex {
        width: 398px;
      }
    }
    div {
      align-self: center;
    }
    figure {
      align-self: center;
    }
    &:nth-child(1) {
      height: 314px;
      margin-top: 151px;
      div.recursos1-sidetext {
        margin-left: 195px;
      }
      div.recurso1-HYPE {
        width: 550px;
        height: 350px;
      }
    }
    &:nth-child(2) {
      height: 788px;
      div.recurso2-sidetext {
        margin-right: 105px;
        margin-left: 110px;
      }
      .recurso2-HYPE {
        width: 650px;
        height: 720px;
      }
    }
    &:nth-child(3) {
      div.recurso3-sidetext {
        margin-left: 127px;
      }
      div.recurso3-HYPE {
        width: 600px;
        height: 350px;
      }
    }
    &:nth-child(4) {
      height: 570px;
      div.resources4-sidetext {
        margin-right: 143px;
        margin-top: 6px;
      }
      div.resources4-HYPE {
        width: 616px;
        height: 360px;
      }
    }
  }
}

.button-container {
  text-align: center;
  button {
    display: flex;
    width: 228px;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 46px;
    background-color: transparent;
    color: #2979ff;
    border: 2px solid #2979ff;
  }
  button.button-contract {
    margin-top: 58px;

    @media (hover: hover) {
      &:hover {
        background-color: #2979ff;
        p {
          color: #f0f1f2;
        }
        img {
          filter: invert(100%) sepia(78%) saturate(0%) hue-rotate(279deg)
            brightness(109%) contrast(770%);
        }
      }
    }
    &:active {
      background-color: #2979ff;
      p {
        color: #f0f1f2;
      }

      img {
        filter: invert(100%) sepia(78%) saturate(0%) hue-rotate(279deg)
          brightness(109%) contrast(770%);
      }
    }
  }
  button.content-expanded {
    margin-top: 22px;
  }
}

.resources-botton-container {
  display: flex;
  background-color: #4f6ffe;
  //width: 1019px;
  width: 1044px;
  height: 252px;
  margin: auto;
  border-radius: 10px;
  //margin-top: 72px;
  //margin-top: 80px;
  margin-top: 112px;
  margin-bottom: 104px;

  div.recursos-banner-HYPE {
    width: 300px;
    height: 200px;
  }

  div.recursos-banner-text {
    align-self: center;
    //margin-left: 48px;
    //margin: 0px 16px 72px;
    margin: 16px 16px 72px;
  }
  figure {
    align-self: center;
    //margin-left: 56px;
    margin-left: 35px;
  }
}

.security-box-title {
  font-family: "Fira Sans", sans-serif;
  font-size: 34px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: 0.77px;
  color: #f0f1f2;
}

.security-box-text {
  //width: 612px;
  width: 637px;
  height: 78px;
  margin-top: 24px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.36px;
  color: #f0f1f2;
}

.grid-major-title {
  font-family: "Fira Sans", sans-serif;
  font-size: 26px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 0.59px;
  color: #252a33;
  margin-top: 48px;
  margin-bottom: 32px;

  &.grid-attributes {
    margin-top: 64px;
  }

  &.us-major-title {
    margin-top: 86px;
  }
}

.resources-link {
  color: #f9fafa;
  text-decoration: underline;
}

.button-text-less {
  display: none;
}

.button-text-more {
  display: block;
}

.button-contract {
  margin-top: 58px;
}
.content-expanded {
  margin-top: 22px;
  .button-text-more {
    display: none;
  }
  .button-text-less {
    display: block;
  }

  .button-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
}

#first-g-background {
  &::before {
    content: "";
    position: absolute;
    background-color: #f4f4f5;
    width: 6000px;
    height: 789px;
    z-index: -2;
    left: -136px;
  }
}

#second-g-background {
  &::before {
    content: "";
    position: absolute;
    background-color: #f4f4f5;
    width: 6000px;
    //height: 541px;
    height: 555px;
    left: -152px;
    top: 12px;
    z-index: -2;
  }
}

@media (max-width: 1200px) {
  .resources-main-title {
    width: 312px;
    margin-top: 72px;
    h1 {
      width: auto;
      margin-bottom: 16px;

      &.us-main-title {
        width: 280px;
      }
    }
  }
  .resources-second-main-title {
    margin-top: 112px;
    //margin-bottom: 96px;

    &.second-main-br {
      margin-top: 137px;
      //margin-top: 167px;
    }
    p.grid-subtitle {
      width: auto;
      letter-spacing: 0.36px;
    }
  }
  ul.resources-first-grid {
    .first-grid-cell {
      h2.flex-item-title {
        &.make-docs {
          //width: 312px;
          width: 309px;
        }
      }
    }
    li.first-grid-cell {
      flex-direction: column;
      margin: 0px;
      margin-bottom: 144px;
      &.control-acess {
        margin-bottom: 120px;
      }
      p.flex-item-text {
        width: auto;
        margin-top: 16px;

        &.last-flex {
          width: auto;
        }
      }
      div {
        order: 2;
      }
      figure {
        order: 1;
        margin-top: 1px;
      }
      &:nth-child(1) {
        //margin-top: 80px;
        margin-top: 60px;
        //margin-top: 78px;
        height: auto;
        margin-bottom: 0px;
        img.flex-img-1 {
          width: 275px;
          height: 166px;
        }
        div {
          margin: auto;
          margin-top: 28px;
          margin-top: 43px;
          &.recursos1-sidetext {
            margin: auto;
            //margin-top: 28px;
            //margin-top: 43px;
            margin-top: 21px;
          }
        }
        div.recurso1-HYPE {
          //width: 275px;
          //height: 166px;
          width: 336px;
          height: 203px;
          margin: auto;
          div {
            margin: auto;
          }
        }
      }

      &:nth-child(2) {
        //margin-top: 138px;
        //margin-bottom: 67px;
        margin-top: 113px;
        //margin-bottom: 96px;
        margin-bottom: 76px;
        &.first-g-br {
          margin-bottom: 94px;
          &::before {
            height: 868px;
            top: -41px;
          }
        }
        div.recurso2-HYPE {
          width: 306px;
          height: 339px;
          width: 348px;
          height: 381px;
        }
        img.flex-img-2 {
          width: 306px;
          height: 339px;
        }
        div.recurso2-sidetext {
          margin: auto;
          //margin-top: 28px;
          margin-top: 18px;
        }
      }

      &:nth-child(3) {
        img.flex-img-3 {
          width: 300px;
          height: 161px;
        }
        div.recurso3-sidetext {
          margin: auto;
          //margin-top: 43px;
          margin-top: 11px;
        }
        div.recurso3-HYPE {
          width: 382px;
          height: 206px;
        }
      }

      &:nth-child(4) {
        margin-bottom: 0px;
        margin-top: 6px;
        img.flex-img-4 {
          width: 273px;
          height: 160px;
        }
        div.resources4-sidetext {
          margin: auto;
          //margin-top: 59px;
          margin-top: 34px;
        }
        div.resources4-HYPE {
          //width: 346px;
          //height: 203px;
          width: 370px;
          height: 227px;
        }
      }
    }
  }

  .grid-major-title {
    margin-bottom: 24px;
    font-size: 21px;
    &.grid-attributes {
      margin-top: 0px;
    }
  }
  .grid-container {
    margin-top: 94px;

    &.first-grid-container {
      margin-top: 48px;
    }

    &.collab-container {
      margin-top: 94px;
    }

    div.mobile {
      display: block;
    }
    div.fade-collab {
      display: block;
      width: 100%;
      height: 227px;
      /*background-image: linear-gradient(
        to bottom,
        rgba(249, 250, 250, 0) 50%,
        #f9fafa 107%
      );*/

      background-image: linear-gradient(
        to bottom,
        rgba(249, 250, 250, 0) -8%,
        #f9fafa 107%
      );
      position: absolute;
      //top: 380px;
      top: 385px;
    }
    div.fade {
      top: 266px;
    }
    div.fade-structure {
      display: block;
      width: 100%;
      height: 227px;
      /*background-image: linear-gradient(
        to bottom,
        rgba(249, 250, 250, 0) 50%,
        #f9fafa 107%
      );*/
      background-image: linear-gradient(
        to bottom,
        rgba(249, 250, 250, 0) 2%,
        #f9fafa 107%
      );
      position: absolute;
      //top: 1334px;
      //top: 1294px;
      top: 732px;
    }
    div.fade-custom {
      display: block;
      width: 100%;
      height: 227px;
      background-image: linear-gradient(
        to bottom,
        rgba(249, 250, 250, 0) 2%,
        #f9fafa 107%
      );
      position: absolute;
      //top: 1334px;
      //top: 1294px;
      top: 568px;
    }
    ul.resources-grid {
      grid-template-columns: 1fr;
      &.collab-mobile {
        //height: 555px;
        height: 546px;
        overflow: hidden;
      }
      &.structure-mobile {
        //height: 1505px;
        //height: 1462px;
        height: 910px;
        overflow: hidden;
      }

      &.custom-mobile {
        height: 746px;
        overflow: hidden;
      }
      &.grid-more {
        height: auto;

        div.fade-collab {
          display: none;
        }
        div.fade-structure {
          display: none;
        }
        div.fade-custom {
          display: none;
        }
      }

      li.grid-cell {
        width: 256px;
        margin-bottom: 32px;
        p {
          width: 256px;
        }
      }
    }
  }

  .button-container {
    button {
      //margin-top: 77px;
      margin-top: 32px;
      //&:hover {
      &:active {
        background-color: #2979ff;
        p {
          color: #f0f1f2;
        }

        img {
          filter: invert(100%) sepia(78%) saturate(0%) hue-rotate(279deg)
            brightness(109%) contrast(770%);
        }
      }
    }
    button.content-expanded {
      margin-top: 14px;
    }
  }
  .resources-botton-container {
    width: 312px;
    //height: 619px;
    height: auto;
    flex-direction: column;
    margin-bottom: 90px;

    div {
      //margin: 0px 16px 72px;
      //margin-top: 0px;
      //margin-left: 16px;
      //margin-right: 16px;
      //margin-bottom: 72px;
      h3.security-box-title {
        text-align: center;
        font-size: 32px;
        letter-spacing: 0.36px;
      }
      p.security-box-text {
        height: auto;
        width: auto;
        text-align: center;
      }
    }
    figure {
      margin: auto;
      margin-top: 36px;
      margin-bottom: 11px;
    }
  }
  #first-g-background {
    &::before {
      content: "";
      position: absolute;
      background-color: #f4f4f5;
      width: 100vw;
      //height: 830px;
      //height: 868px;
      height: 848px;
      z-index: -2;
      transform: translateX(-50%);
      left: 50%;
      //top: -62px;
      //top: -79px;
      top: -40px;
    }
    &.first-g-br {
      //margin-bottom: 94px;
      //margin-bottom: 58px;
      margin-bottom: 95px;
      margin-top: 118px;
      &::before {
        height: 868px;
        top: -42px;
      }
    }
  }
  #second-g-background {
    &.second-g-br {
      &::before {
        //height: 708px;
        height: 713px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      background-color: #f4f4f5;
      width: 1440px;
      //height: 720px;
      //height: 710px;
      height: 708px;
      //top: -86px;
      //top: -72px;
      top: -49px;
      z-index: -2;
      transform: translateX(-50%);
      left: 50%;
    }
  }
}
